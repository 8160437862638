import React, { useState, useEffect, useRef } from 'react'
import MDEditor, { bold, commands, italic, image, unorderedListCommand, orderedListCommand, checkedListCommand, link, title, codePreview, divider, codeEdit, codeLive, strikethrough, title2, title3, title4, title1 } from '@uiw/react-md-editor';
import SvgIcon from '../../../svg/SvgIcon';
import DetailCardCommentSection from './DetailCardCommentSection';
import ReactPlayer from 'react-player/youtube'
import { getYoutubeThumbnailUrl } from '../../../features/postApi/postSlice';

function FoodDetailCard({
    item,
    classes,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleScrollEvent,
    number,
    currentActive,
    setShowContactsPop,
    setShowOrderPop,
    selectedAvatar, defaultAvatars,
    openShare,
    LikePost,
    BookmarkPost,
    setReportData,
    ShareThisPost,
    getAllComments,
    AddCommentOnPost,
    LikeDislikeComment,
    isLogin,

    AddLikeDislikeComment,
    FunctionSetShowComments,
    ItemCurrentFromParent,
    ShowCommentsPop,
    setItemParentCurrent
    // ShowCommentsPop,
    // setShowCommentsPop
}) {
    const [ShowVideo, setShowVideo] = useState(false);
    const ref = useRef();
    useEffect(() => {
        if (currentActive !== number) {
            // console.log(currentActive, number);
            setShowVideo(false)
        }

        // console.log(currentActive, number);
        if(currentActive === number) {
            console.log(item)
            setItemParentCurrent(item);
        }
    }, [currentActive, number])

    const [ItemCurrent, setItemCurrent] = useState(item);

    //     const [LoadingComments, setLoadingComments] = useState(false);
    //     const [LoadingInternalComments, setLoadingInternalComments] = useState(false);

    //   const [ShowCommentsPop, setShowCommentsPop] = useState(null);

    // const [EnteredComment, setEnteredComment] = useState('');
    // const [DisabelSendbutton, setDisabelSendbutton] = useState(false);

    // const [CurrentActiveComment, setCurrentActiveComment] = useState(null);
    // const [CurrentActiveCommentText, setCurrentActiveCommentText] = useState('');

    useEffect(() => {
        setItemCurrent(ItemCurrent);
    }, [item])

    async function setLikePost(Id, act) {
        const tempItem = JSON.parse(JSON.stringify(ItemCurrent));
        console.log(act);
        const value = await LikePost(Id, act);
        if (value === 'Success') {
            // console.log(value, act);
            if (act === 'f' || act === null) {
                tempItem.userAction = 't';
                tempItem.NumberOfLikes = Number(tempItem.NumberOfLikes) + 1;
            } else {
                tempItem.userAction = 'f';
                tempItem.NumberOfLikes = Number(tempItem.NumberOfLikes) - 1;
            }
        }
        console.log(tempItem);
        setItemCurrent(tempItem);
    }

    async function setBookmarkPost(Id, act) {
        const tempItem = JSON.parse(JSON.stringify(ItemCurrent));
        // console.log(act);
        const value = await BookmarkPost(Id, act);
        if (value === 'Success') {
            // console.log(value, act);
            if (act === 'f' || act === null) {
                tempItem.bookmark = 't';
            } else {
                tempItem.bookmark = 'f';
            }
        }
        // console.log(tempItem);
        setItemCurrent(tempItem);
    }
    

    // useEffect(() => {
    //     console.log(ItemCurrentFromParent)
    //   if(ItemCurrentFromParent && ItemCurrentFromParent.ID === ItemCurrent.ID && !ShowCommentsPop) {
    //     setItemCurrent(JSON.parse(JSON.stringify(ItemCurrentFromParent)));
    //     setItemParentCurrent(null);
    //   }
    // }, [ShowCommentsPop, ItemCurrentFromParent])
    




    //   const [AllComments, setAllComments] = useState(null);


    // const [enableInput, setEnableInput] = useState(false);

    // function FunctionSetShowComments(arg, input) {
    //     setShowCommentsPop(arg)
    //     setEnableInput(input)
    // }


    // useEffect(async function () {
    //     if (ShowCommentsPop) {
    //         setLoadingComments(true);
    //         setDisabelSendbutton(true);
    //         const LatestComments = await getAllComments(ItemCurrent.ID);
    //         setLoadingComments(false);
    //         if (LatestComments) {
    //             const TempItemCurrent = JSON.parse(JSON.stringify(ItemCurrent));
    //             TempItemCurrent.Comments = LatestComments;
    //             setItemCurrent(TempItemCurrent);
    //         }
    //         console.log(LatestComments);
    //         setDisabelSendbutton(false);
    //     }

    // }, [ShowCommentsPop])


    // async function CommentOnPost() {
    //     console.log(EnteredComment, ItemCurrent.ID)
    //     setDisabelSendbutton(true)
    //     setLoadingComments(true);
    //     const LatestComments = await AddCommentOnPost(EnteredComment, ItemCurrent.ID);
    //     setLoadingComments(false);
    //     if (LatestComments) {
    //         const TempItemCurrent = JSON.parse(JSON.stringify(ItemCurrent));
    //         console.log(TempItemCurrent)
    //         TempItemCurrent.Comments = [LatestComments, ...TempItemCurrent.Comments];
    //         setItemCurrent(TempItemCurrent);
    //         setEnteredComment('');
    //         setDisabelSendbutton(false)
    //     }
    // }


    // async function AddLikeDislikeComment(comment, action, ParentCommentID) {
    //     const ActionedComment = await LikeDislikeComment(comment, action, ItemCurrent.ID, ParentCommentID);
    //     console.log(comment, action, ItemCurrent.ID, ParentCommentID, ActionedComment);

    //     if (ActionedComment) {
    //         let tempItemCurrent = JSON.parse(JSON.stringify(ItemCurrent));
    //         const tempItemCurrentComments = tempItemCurrent.Comments.map((itm) => {
    //             let insideTemp = JSON.parse(JSON.stringify(itm));
    //             if (ParentCommentID) {
    //                 insideTemp = insideTemp.map((imt) => {
    //                     if (imt.ID === ParentCommentID) {
    //                         return ActionedComment;
    //                     }
    //                     return itm
    //                 })
    //             }
    //             if (insideTemp.ID === ActionedComment.ID) {
    //                 return ActionedComment;
    //             }
    //             return insideTemp
    //         })
    //         tempItemCurrent.Comments = tempItemCurrentComments;
    //         setItemCurrent(tempItemCurrent);
    //     }
    // }


    // async function AddInterCommentOnDescussion(ParentID) {
    //     setDisabelSendbutton(true)
    //     setLoadingInternalComments(true);
    //     console.log(ParentID, CurrentActiveCommentText, ItemCurrent.ID);
    //     const LatestComments = await AddCommentOnPost(CurrentActiveCommentText, ItemCurrent.ID, ParentID);
    //     setLoadingInternalComments(false);
    //     setDisabelSendbutton(false);
    //     if (LatestComments) {
    //         let TempItem = JSON.parse(JSON.stringify(ItemCurrent));
    //         const TempItemCurrent = TempItem.Comments.map((itm) => {
    //             const tmpItm = JSON.parse(JSON.stringify(itm));
    //             if (tmpItm.ID === ParentID) {
    //                 if (!tmpItm.Comments) tmpItm.Comments = [];
    //                 tmpItm.Comments = [LatestComments, ...tmpItm.Comments];
    //                 if (!tmpItm.TotalReplies) tmpItm.TotalReplies = 0;
    //                 tmpItm.TotalReplies = Number(tmpItm.TotalReplies) + 1;
    //             }
    //             return tmpItm;
    //         })
    //         TempItem.Comments = TempItemCurrent
    //         console.log(TempItemCurrent, TempItem);
    //         setItemCurrent(TempItem);
    //         setCurrentActiveCommentText('');
    //     }
    // }



    // function AddCommentToList(comment) {
    //     const tempComment = [comment, ...CurrentComments];
    //     setCurrentComments(tempComment);
    // }


    return (
        <div
            className={`${classes} FoodDetailCard ${item.Type === 'Shorts' ? 'ShortVideo' : ''}`}
            // onScroll={ShowCommentsPop === null ? handleScrollEvent : () => {}}
            // onTouchStart={ShowCommentsPop === null ? handleTouchStart : () => {}}
            // onTouchMove={ShowCommentsPop === null ? handleTouchMove : () => {}}
            style={{ 'zIndex': 100 - number }}
            // onTouchEnd={ShowCommentsPop === null ? handleTouchEnd : () => {}}
            id={item.ID}
        >
            {
                ((currentActive >= number && (currentActive - number) <= 5) ||
                    (number >= currentActive && (number - currentActive) <= 5)) &&
                <>

                    <div className='FoodDetailCard_Top' style={{ 'backgroundImage': `url(${getYoutubeThumbnailUrl(item.VideoLink)})`, 'backgroundSize': 'cover', 'backgroundRepeat': 'no-repeat', 'backgroundPosition': 'center' }}>
                        {
                            currentActive === number &&
                            <>
                                <ReactPlayer playing={true} volume="1" playIcon controls url={`${item.VideoLink}`} width={`${window.innerWidth}px`} height={`${(window.innerWidth * 9) / 16}px`} />
                                {/* <iframe src={`${item.VideoLink}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}
                            </>
                        }
                    </div>

                    <div className='FoodDetailCard_Bottom'>
                        <div className='VideoOwnerDetails'>
                            <h6 className='px-1 py-0' onClick={() => openShare(item.YoutubeChannelLink, 'Youtube', item.ChannelID)}><i>{item.YoutubeChannelName}</i></h6>
                        </div>
                        <div className='FoodDetailCard_RestaurantDetails'>
                            <div className='leftDetails'>
                                <h4>{item.BussinessName}</h4>
                                <div className='JointDataInfo'>
                                    {
                                        item.BussinessLocation &&
                                        <span className='JointLocation'>
                                            <SvgIcon icon='mappin-fill' size="14" fill='#767676' />
                                            {item.BussinessLocation}
                                        </span>
                                    }
                                    {
                                        item.BussinessTiming &&
                                        <span className='JointDataTime'>
                                            <ion-icon name="time"></ion-icon>
                                            {item.BussinessTiming}
                                        </span>
                                    }
                                    {
                                        item.Cost &&
                                        <span className='JointDataLocation'>
                                            <SvgIcon icon='wallet-fill' size="14" fill='#767676' />
                                            {item.Cost}/person
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className='centerDetails'>
                                <button disabled={item.contactLinks?.length === 0} className='contactDetails' onClick={() => setShowContactsPop(item.contactLinks)}>
                                    <span>
                                        Contact
                                    </span>
                                    <SvgIcon icon='call-fill' size="18" fill='#F55725' />
                                </button>
                                <button disabled={item.externalLinks?.length === 0} className='contactDetails' onClick={() => setShowOrderPop(item.externalLinks)}>
                                    <span>
                                        Order
                                    </span>
                                    <SvgIcon icon='notebook-fill' size="18" fill='#F55725' />
                                </button>
                                <button className='contactDetails' onClick={() => openShare(item.googleLink, 'Google Map')}>
                                    <span>
                                        Visit
                                    </span>
                                    <SvgIcon icon='mappin-fill' size="18" fill='#F55725' />
                                </button>
                            </div>

                        </div>
                        {
                            currentActive === number &&
                            <div className='FoodDetailCard_Questions mt-1' id={`FoodCardComment${number}`}>
                                <div className='BackShadow' />

                                <DetailCardCommentSection
                                    PostID={item.ID}
                                    Comments={ItemCurrentFromParent ? ItemCurrentFromParent.Comments : ItemCurrent.Comments}
                                    AddLikeDislikeComment={AddLikeDislikeComment}
                                    setShowCommentsPop={FunctionSetShowComments}
                                    setReportData={setReportData}
                                    item={item}
                                />
                                {
                                    ItemCurrent.Comments && ItemCurrent.Comments.length > 2 &&
                                    <div className='SeeMoreSection mt-5'>
                                        <button
                                            onClick={() => FunctionSetShowComments(item.ID, false)}
                                        >See More Discussions</button >
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className='FoodDetailCard_Action'>
                        <button className='btn btn-sm' disabled={!isLogin} onClick={() => setLikePost(item.ID, ItemCurrent.userAction)}>
                            {
                                ItemCurrent.userAction === 't' ?
                                    <SvgIcon icon='heart-fill' className='LikedPost' size="18" />
                                    :
                                    <SvgIcon icon='heart' size="18" />
                            }
                            {ItemCurrent.NumberOfLikes}
                        </button>

                        <button className='btn btn-sm' disabled={!isLogin} onClick={() => setBookmarkPost(item.ID, ItemCurrent.bookmark)}>
                            {
                                ItemCurrent.bookmark === 't' ?
                                    <SvgIcon icon='bookmark-fill' size="18" />
                                    :
                                    <SvgIcon icon='bookmark' size="18" />
                            }
                        </button>

                        <button className='btn btn-sm' onClick={() => ShareThisPost(item.BussinessName, item.ShareLink ? item.ShareLink : `https://www.foodaspas.com/FoodJointsNearby/${item.ID}`)}>

                            <SvgIcon icon='share-fill' size="18" />
                        </button>

                        <button className='btn btn-sm' disabled={!isLogin} onClick={() => setReportData({ 'type': 'PostReport', 'data': item, 'Post_ID': item.ID })}>
                            <SvgIcon icon='warning-fill' size="18" />
                        </button>
                    </div>

                    {/* {
                        currentActive === number &&
                        <div className={`CommenttsPopup ${ShowCommentsPop !== null ? 'show' : ''}`}>
                            <div className='BgColor' onClick={() => FunctionSetShowComments(null, false)} />
                            <DetailCardCommentSection
                                PostID={ShowCommentsPop}
                                Comments={ItemCurrent.Comments}
                                setShowCommentsPop={FunctionSetShowComments}
                                enableInput={enableInput}
                                EnteredComment={EnteredComment}
                                setEnteredComment={setEnteredComment}
                                CommentOnPost={CommentOnPost}
                                DisabelSendbutton={DisabelSendbutton}
                                AddLikeDislikeComment={AddLikeDislikeComment}
                                LoadingComments={LoadingComments}

                                CurrentActiveComment={CurrentActiveComment}
                                setCurrentActiveComment={setCurrentActiveComment}
                                CurrentActiveCommentText={CurrentActiveCommentText}
                                setCurrentActiveCommentText={setCurrentActiveCommentText}

                                AddInternalComment={AddInterCommentOnDescussion}
                                LoadingInternalComments={LoadingInternalComments}
                                setLoadingInternalComments={setLoadingInternalComments}
                                setReportData={setReportData}

                                isLogin={isLogin}
                            />
                        </div>
                    } */}

                </>
            }
        </div>
    )
}

export default FoodDetailCard
import React, { useState, useRef, useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import FoodDetailCard from './Components/FoodDetailCard'
import { connect } from "react-redux"
import config from '../../config'
import axios from '../../AxiosConfig'
// import PageBottomNavigation from './Components/PageBottomNavigation'
import DetailCardCommentSection from './Components/DetailCardCommentSection';
import SvgIcon from '../../svg/SvgIcon';
import BackButton from './Components/BackButton';
import { useParams } from 'react-router';
import { setFeeds } from '../../features/postApi/postSlice'
import GetLocationShow from './Components/GetLocationShow'
import ReportModal from './Components/ReportModal'
import FoodLoadingCard from './Components/FoodLoadingCard'
import useDetectLocationInBackground from '../../hooks/useDetectLocationInBackground'
import { setUserLocationDetails, getCurrentUserLocationAndSet, setSelectedCity } from '../../features/auth/authSlice'
import { Badge } from 'react-bootstrap'
import AllPostViewCard from './AllPostViewCard'
import useGetSetLocationToLocal from '../../hooks/useGetSetLocationToLocal'
import DownloadCard from '../Pages/DownloadCard'

function FoodDetailsSharePage({
    threshold = 10,
    userDeviceDetails,
    selectedAvatar,
    defaultAvatars,
    isLogin,
    Feeds,
    setFeeds,
    userLocationDetails,
    CurrentFilters,
    setUserLocationDetails,
    method,
    userLocation,
    selectedCity,
    getCurrentUserLocationAndSet,
    setSelectedCity
}) {
    const { lat, long, getLocation, Error } = useDetectLocationInBackground();
    const { getSelectedFromLocal } = useGetSetLocationToLocal();
    const { FoodPostID } = useParams();
    const splideRef = useRef(null);
    const touchStartX = useRef(null);
    const touchEndX = useRef(null);

    const [cardHeight, setCardHeight] = useState(window.innerHeight - 45);
    const [CurrentPageIndex, setCurrentPageIndex] = useState(0);
    const [FirstPostID, setFirstPostID] = useState(null);
    
    const [currentActive, setCurrentActive] = useState(0);
    const [prevIndex, setPrevIndex] = useState(-1);

    const [ShowContactPop, setShowContactsPop] = useState(null);
    const [ShowOrderPop, setShowOrderPop] = useState(null);
    const [isDoneCard, setIsDoneCard] = useState(null);

    const [ReportData, setReportData] = useState(null);

    let currentIndex = 0

    useEffect(() => {
        setFirstPostID(FoodPostID);
    }, FoodPostID)

    // useEffect(() => {
    //     if (lat && long) {
    //         getCurrentUserLocationAndSet({ "latitude": lat, "longitude": long })
    //     }
    // }, [lat, long])

    function getPostToScroll() {
        // if (userLocationDetails === null) {
        //     getLocation();
        // }
        if (!isLogin) {
            let urlToUse = `/Posts`;
            // console.log(userLocation)
            const SendData = {
                pageSize: 10,
                pageIndex: CurrentPageIndex,
                // lat: (userLocationDetails && userLocationDetails.lat) ? userLocationDetails.lat : null,
                // long: (userLocationDetails && userLocationDetails.long) ? userLocationDetails.long : null,
                place: selectedCity,
                action: method
            }

            // if (CurrentFilters) {
            //     SendData.CurrentFilters = CurrentFilters
            // }
            // console.log(FirstPostID, SendData);
            if (FirstPostID) {
                SendData.FirstPostID = FirstPostID;
            }
            // if(!SendData.lat && !SendData.place) {
            //     return
            // }
            axios.post(urlToUse, SendData).then(res => {
                if (res.data.message === 'Success') {
                    if (res.data.data.items.length > 0) {
                        console.log([...Feeds, ...res.data.data.items]);
                        setFeeds([...Feeds, ...res.data.data.items]);
                        setCurrentPageIndex(prevState => prevState + 1);
                        if (FirstPostID !== null) {
                            setFirstPostID(null);
                        }
                    }
                }
            })
        }
    }

    useEffect(() => {
        // let wait = setTimeout(() => {
            if (FirstPostID) {
                // console.log('log1', userLocationDetails, selectedCity)
                getPostToScroll()
            }
        // }, 2000);

        return () => {
            // console.log('log2')
            // clearTimeout(wait);
            // setFeeds([]);
        }
    }, [FirstPostID])

    useEffect(() => {

        // console.log(isDoneCard, currentActive)
        if(isDoneCard) {
            if(isDoneCard < currentActive) {
                setSelectedCity(null);
                setIsDoneCard(null);
            }
        
            if(isDoneCard > currentActive) {
                setIsDoneCard(null);
                setSelectedCity(getSelectedFromLocal());
            }
        }
    }, [currentActive])
    

    const handleActive = (e) => {
        setTimeout(() => {
            currentIndex = splideRef.current.splide.index;
            setCurrentActive(currentIndex)
        }, 1);
    }
    

    const handleTouchStart = (e) => {
        // setPrevIndex(splideRef.current.splide.index);
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        touchEndX.current = e.changedTouches[0].clientX;
        // console.log(e);
        handleSwipe();
    };

    const handleSwipe = () => {
        const swipeDistance = touchStartX.current - touchEndX.current;
        const swipeThreshold = 50;

        if (swipeDistance > swipeThreshold) {
            // console.log('Swiped left');
        } else if (swipeDistance < -swipeThreshold) {
            // console.log('Swiped right');
        }
    };


    async function LikePost(Id, Action) {
        const sendData = {
            "Post_ID": Id,
            "LikeStatus": true
        }
        if (Action === 't') {
            sendData.LikeStatus = false
        }
        let DatatoReturn = null;
        await axios.post(`/posts/postLike`, sendData)
            .then(res => {
                if (res.data.message === 'Success') {
                    DatatoReturn = 'Success'
                } else {
                    DatatoReturn = 'Failed'
                }
            })
            .catch(err => {
                DatatoReturn = 'Failed'
            });

        // console.log(DatatoReturn);
        return DatatoReturn;
    }

    async function BookmarkPost(Id, Action) {
        const sendData = {
            "Post_ID": Id,
            "BookmarkStatus": true
        }
        if (Action === 't') {
            sendData.BookmarkStatus = false
        }
        let DatatoReturn = null;
        await axios.post(`/posts/postBookmark`, sendData)
            .then(res => {
                if (res.data.message === 'Success') {
                    DatatoReturn = 'Success'
                } else {
                    DatatoReturn = 'Failed'
                }
            })
            .catch(err => {
                DatatoReturn = 'Failed'
            });

        return DatatoReturn;
    }

    async function getAllComments(PostID) {
        let CommentData = null
        await axios.get(`/posts/getPostComments/${PostID}`).then(res => {
            if (res.data.message === 'Success') {
                if (res.data.data.length > 0) {
                    // setAllComments(res.data.data);
                    CommentData = res.data.data;
                }
            }
        })

        return CommentData;
    }


    async function AddCommentOnPost(EnteredComment, PostID, Comment_ID) {
        let CommentData = null;
        if (EnteredComment.trim().length > 0) {
            const sendData = {
                "CommentText": EnteredComment,
                "Post_ID": PostID
            }
            if (Comment_ID) {
                sendData.Reference_Comment_ID = Comment_ID;
            }
            await axios.post(`/posts/postComments`, sendData).then(res => {
                if (res.data.message === 'Success') {
                    const tempComment = {
                        "CommentText": EnteredComment,
                        "ID": res.data.data,
                        "TotalLikes": 0,
                        "TotalDisLikes": 0,
                        "TotalReplies": 0,
                        "userAction": null,
                        "UserImage": selectedAvatar
                    }
                    CommentData = tempComment;
                }
            })
        }
        return CommentData
    }

    async function LikeDislikeComment(Comment, Action, PostID, ParentCommentID) {
        let SetAction = true;
        let TotalLikes = Comment.TotalLikes;
        let TotalDisLikes = Comment.TotalDisLikes;
        // console.log(Comment.userAction, Action);
        if (Comment.userAction === 't' && Action === 't') {
            SetAction = null;
            TotalLikes = Number(TotalLikes) - 1;
        } else if (Comment.userAction === 'f' && Action === 'f') {
            SetAction = null;
            TotalDisLikes = Number(TotalDisLikes) - 1;
        } else if (Comment.userAction === 't' && Action === 'f') {
            SetAction = false;
            TotalDisLikes = Number(TotalDisLikes) + 1;
            TotalLikes = Number(TotalLikes) - 1;
        } else if (Comment.userAction === 'f' && Action === 't') {
            SetAction = true;
            TotalDisLikes = Number(TotalDisLikes) - 1;
            TotalLikes = Number(TotalLikes) + 1;
        } else if (Comment.userAction === null && Action === 't') {
            SetAction = true;
            TotalLikes = Number(TotalLikes) + 1;
        } else if (Comment.userAction === null && Action === 'f') {
            SetAction = false;
            TotalDisLikes = Number(TotalDisLikes) + 1;
        }

        let tempComment = null;
        await axios.post(`/comments/commentLike`, {
            "Post_ID": PostID,
            "Comment_ID": Comment.ID,
            "LikeStatus": SetAction,
            "PrevStatus": Comment.userAction
        }).then(res => {
            if (res.data.message === 'Success') {
                const TempComment = JSON.parse(JSON.stringify(Comment));
                TempComment.TotalLikes = TotalLikes;
                if (SetAction === null) TempComment.userAction = null;
                if (SetAction === true) TempComment.userAction = 't';
                if (SetAction === false) TempComment.userAction = 'f';
                TempComment.TotalDisLikes = TotalDisLikes;
                // console.log(TempComment);
                tempComment = TempComment
                // AddLikeDislikeComment(TempComment, ParentCommentID)
            }
        })

        return tempComment
    }

    function openAsExternalLink(url) {
        window.open(url, '_blank');
    }

    const handleGoogleClick = ({ url }) => {
        const googleMapsUrl = `googlemap://open?url=${url.substring(url.indexOf('?') + 1)}`;
        window.open(googleMapsUrl, '_blank');

        setTimeout(() => {
            openAsExternalLink(url, '_blank');
        }, 1000);
    };

    const handleYoutubeClick = ({ url, channelID }) => {
        const youTubeUrl = `youtube://www.youtube.com/channel/${channelID}`;
        window.open(youTubeUrl, '_system', 'location=yes');

        setTimeout(() => {
            openAsExternalLink(url, '_blank');
        }, 1000);
    }

    const handleZomatoClick = ({ url }) => {
        const applink = `zomato://open?url=${url}`; // url.replace('https://www.zomato.com/', 'https://link.zomato.com/')
        const ZomatoUrl = applink;
        // console.log(ZomatoUrl);
        // window.location.href = ZomatoUrl;
        window.open(applink, '_system', 'location=yes');

        setTimeout(() => {
            openAsExternalLink(url, '_blank');
        }, 1000);
    };

    const handleSwiggyClick = ({ url }) => {
        const applink = `swiggy://open?url=${url}`; // url.replace('https://www.zomato.com/', 'https://link.zomato.com/')
        const SwiggyUrl = applink;
        // console.log(SwiggyUrl);
        // window.location.href = SwiggyUrl;
        window.open(SwiggyUrl, '_system', 'location=yes');

        setTimeout(() => {
            openAsExternalLink(url, '_blank');
        }, 1000);
    };

    const handleWhatsAppLink = ({ url }) => {
        const applink = `https://api.whatsapp.com/send?phone=/${url}`;
        window.open("whatsapp://send?phone=" + encodeURIComponent(url), '_system');

        setTimeout(() => {
            openAsExternalLink(applink, '_blank');
        }, 1000);
    }

    const handleCall = ({ url }) => {
        window.open(`tel:${encodeURIComponent(url)}`, '_blank');
    }
    const openShare = (link, BussinessName, Data) => {
        // console.log(link);
        if (BussinessName === 'Google Map') {
            handleGoogleClick({ url: link, mapData: Data });
        } else if (BussinessName === 'Youtube') {
            handleYoutubeClick({ url: link, channelID: Data });
        } else if (BussinessName === 'Zomato') {
            handleZomatoClick({ url: link });
        } else if (BussinessName === 'Swiggy') {
            handleSwiggyClick({ url: link });
        } else if (BussinessName === 'Whatsapp') {
            handleWhatsAppLink({ url: link });
        } else if (BussinessName === 'Call') {
            handleCall({ url: link });
        } else {
            openAsExternalLink(link);
        }
    }

    function ShareThisPost(BussinessName, link) {

        if (config.cordova) {
            var options = {
                message: BussinessName, // not supported on some apps (Facebook, Instagram)
                subject: BussinessName, // fi. for email
                chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
                url: link
            };

            var onSuccess = function (result) {
                // console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
                // console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
            };

            var onError = function (msg) {
                console.log("Sharing failed with message: " + msg);
            };

            window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        } else {

            if (navigator.share) {
                // console.log(link)
                const shareData = {
                    title: BussinessName,
                    text: BussinessName,
                    url: link,
                };

                // console.log(shareData)
                navigator.share(shareData)
                    .then(() => console.log("Link shared successfully!"))
                    .catch((error) => console.error("Error sharing link:", error));
            } else {
                // console.log("Share API not supported on this browser.");
                window.open(link, '_blank');
            }
        }

    }


    const [ItemCurrent, setItemCurrent] = useState(null);
    const [LoadingComments, setLoadingComments] = useState(false);
    const [LoadingInternalComments, setLoadingInternalComments] = useState(false);

    const [ShowCommentsPop, setShowCommentsPop] = useState(null);

    const [EnteredComment, setEnteredComment] = useState('');
    const [DisabelSendbutton, setDisabelSendbutton] = useState(false);

    const [CurrentActiveComment, setCurrentActiveComment] = useState(null);
    const [CurrentActiveCommentText, setCurrentActiveCommentText] = useState('');

    const [enableInput, setEnableInput] = useState(false);

    function FunctionSetShowComments(arg, input) {
        setShowCommentsPop(arg)
        setEnableInput(input)
        // console.log(arg)
    }

    useEffect(async () => {
        if(currentActive && Feeds[currentActive].ID) {
            const CurrentItemID = Feeds[currentActive].ID;
            const LatestComments = await getAllComments(CurrentItemID);
            // console.log(LatestComments);
            setLoadingComments(false);
            if (LatestComments) {
                const TempItemCurrent = JSON.parse(JSON.stringify(ItemCurrent));
                TempItemCurrent.Comments = LatestComments;
                setItemCurrent(TempItemCurrent);
            }
        }
    }, [currentActive])
    


    async function CommentOnPost() {
        // console.log(EnteredComment, ItemCurrent.ID)
        setDisabelSendbutton(true)
        setLoadingComments(true);
        const LatestComments = await AddCommentOnPost(EnteredComment, ItemCurrent.ID);
        setLoadingComments(false);
        if (LatestComments) {
            const TempItemCurrent = JSON.parse(JSON.stringify(ItemCurrent));
            // console.log(TempItemCurrent)
            TempItemCurrent.Comments = [LatestComments, ...TempItemCurrent.Comments];
            setItemCurrent(TempItemCurrent);
            setEnteredComment('');
            setDisabelSendbutton(false)
        }
    }


    async function AddLikeDislikeComment(comment, action, ParentCommentID) {
        const ActionedComment = await LikeDislikeComment(comment, action, ItemCurrent.ID, ParentCommentID);
        // console.log(comment, action, ItemCurrent.ID, ParentCommentID, ActionedComment);

        if (ActionedComment) {
            let tempItemCurrent = JSON.parse(JSON.stringify(ItemCurrent));
            const tempItemCurrentComments = tempItemCurrent.Comments.map((itm) => {
                let insideTemp = JSON.parse(JSON.stringify(itm));
                if (ParentCommentID) {
                    insideTemp = insideTemp.map((imt) => {
                        if (imt.ID === ParentCommentID) {
                            return ActionedComment;
                        }
                        return itm
                    })
                }
                if (insideTemp.ID === ActionedComment.ID) {
                    return ActionedComment;
                }
                return insideTemp
            })
            tempItemCurrent.Comments = tempItemCurrentComments;
            setItemCurrent(tempItemCurrent);
        }
    }


    async function AddInterCommentOnDescussion(ParentID) {
        setDisabelSendbutton(true)
        setLoadingInternalComments(true);
        // console.log(ParentID, CurrentActiveCommentText, ItemCurrent.ID);
        const LatestComments = await AddCommentOnPost(CurrentActiveCommentText, ItemCurrent.ID, ParentID);
        setLoadingInternalComments(false);
        setDisabelSendbutton(false);
        if (LatestComments) {
            let TempItem = JSON.parse(JSON.stringify(ItemCurrent));
            const TempItemCurrent = TempItem.Comments.map((itm) => {
                const tmpItm = JSON.parse(JSON.stringify(itm));
                if (tmpItm.ID === ParentID) {
                    if (!tmpItm.Comments) tmpItm.Comments = [];
                    tmpItm.Comments = [LatestComments, ...tmpItm.Comments];
                    if (!tmpItm.TotalReplies) tmpItm.TotalReplies = 0;
                    tmpItm.TotalReplies = Number(tmpItm.TotalReplies) + 1;
                }
                return tmpItm;
            })
            TempItem.Comments = TempItemCurrent
            // console.log(TempItemCurrent, TempItem);
            setItemCurrent(TempItem);
            setCurrentActiveCommentText('');
        }
    }

    return (
        <div className='height100vh'>
            <div className='FoodDetailsPageContainer'>
                <div className='UserLocationBar d-flex justify-content-center'>
                    <h2 className='text-center mb-0' style={{'fontWeight' : '900'}} >
                    foodaspas
                    </h2>
                </div>

                <div>
                    <Splide
                        ref={splideRef}
                        id="splide"
                        options={{
                            direction: 'ttb',
                            height: '100%',
                            width: '100%',
                            autoWidth: true,
                            autoHeight: true,
                            perPage: 1,
                            perMove: 1,
                            gap: 0,
                            pagination: false,
                            arrows: false,
                            // arrows: (windowWidth > 700) ? true : false,
                            type: 'slide',
                            flickPower: 200,
                            flickMaxPages: 1,
                            // wheel: (windowWidth > 700) ? true : false,
                            wheelMinThreshold: 150
                        }}
                        className={'cardSwiper'}
                        onActive={handleActive}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    >


                        {
                            Feeds?.map((item, i) => {
                                return (
                                    <SplideSlide key={i}>
                                        {
                                            item.Action === 'completed' &&
                                                <AllPostViewCard
                                                    // handleScroll={handleScroll}
                                                    // handleTouchStart={handleTouchStart}
                                                    // handleTouchMove={handleTouchMove}
                                                    // handleTouchEnd={handleTouchEnd}
                                                    item={item}
                                                    setIsDoneCard={setIsDoneCard}
                                                    currentActive={currentActive}
                                                    number={i}
                                                />
                                        }
                                        {
                                            //  (i >=  (currentActive - renderCardLimit)) && (i <=  (currentActive + renderCardLimit)) &&
                                            item.Action === 'download' &&
                                                <DownloadCard
                                                    // handleScroll={handleScroll}
                                                    // handleTouchStart={handleTouchStart}
                                                    // handleTouchMove={handleTouchMove}
                                                    // handleTouchEnd={handleTouchEnd}
                                                    item={item}
                                                />
                                        }
                                        {
                                            item.Action !== 'completed'  && item.Action !== 'download' &&
                                                <FoodDetailCard
                                                    item={item}
                                                    classes={`card${i}`}
                                                    key={i}
                                                    number={i}
                                                    currentActive={currentActive}
                                                    // handleTouchStart={handleTouchStart}
                                                    // handleTouchMove={handleTouchMove}
                                                    // handleTouchEnd={handleTouchEnd}
                                                    // handleScrollEvent={handleScroll}
                                                    setShowContactsPop={setShowContactsPop}
                                                    setShowOrderPop={setShowOrderPop}
                                                    selectedAvatar={selectedAvatar}
                                                    defaultAvatars={defaultAvatars}
                                                    openShare={openShare}
                                                    LikePost={LikePost}
                                                    BookmarkPost={BookmarkPost}
                                                    setReportData={setReportData}
                                                    ShareThisPost={ShareThisPost}
                                                    NoDrowerComponent
                                                    getAllComments={getAllComments}
                                                    AddCommentOnPost={AddCommentOnPost}
                                                    LikeDislikeComment={LikeDislikeComment}
                                                    isLogin={isLogin}

                                                    AddLikeDislikeComment={AddLikeDislikeComment}
                                                    FunctionSetShowComments={FunctionSetShowComments}
                                                    ItemCurrentFromParent={ItemCurrent}
                                                    ShowCommentsPop={ShowCommentsPop}
                                                    setItemParentCurrent={setItemCurrent}
                                                />
                                        }
                                    </SplideSlide>
                                )
                            })
                        }

                        {
                            Feeds.length === 0 &&
                            <>
                                <FoodLoadingCard />
                            </>
                        }

                    </Splide>
                </div>

                <div className={`ContactPopup ${ShowContactPop ? 'show' : ''}`}>
                    <div className='BgColor' onClick={() => setShowContactsPop(false)} />
                    <ul className='ContactsContainer'>
                        {
                            ShowContactPop && ShowContactPop.length > 0 &&
                            ShowContactPop.map((Contact, i) => {
                                return (
                                    <li key={i}>
                                        {
                                            Contact.type === 'Call' &&
                                            <button disabled={Contact.Link === null || Contact.Link === ''} onClick={() => openShare(`${Contact.Link}`, Contact.type)}>
                                                <SvgIcon icon='call-fill' fill='#000' />
                                                <span>{Contact.type}</span>
                                            </button>
                                        }
                                        {
                                            Contact.type === 'Whatsapp' &&
                                            <button disabled={Contact.Link === null || Contact.Link === ''} onClick={() => openShare(`${Contact.Link}`, Contact.type)}>
                                                <SvgIcon icon='whatsapp-fill' fill='#00BD35' />
                                                <span>{Contact.type}</span>
                                            </button>
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className={`ContactPopup ${ShowOrderPop ? 'show' : ''}`}>
                    <div className='BgColor' onClick={() => setShowOrderPop(false)} />
                    <ul className='ContactsContainer'>
                        {
                            ShowOrderPop && ShowOrderPop.length > 0 &&
                            ShowOrderPop.map((Contact, i) => {
                                return (
                                    <li key={i}>
                                        <button disabled={Contact.Link === null || Contact.Link === ''} onClick={() => openShare(Contact.Link, Contact.type)}>
                                            {
                                                Contact.type === 'Zomato' &&
                                                <img src='/assets/img/zomato.svg' className='JointSocialLinkImage' />
                                            }
                                            {
                                                Contact.type === 'Swiggy' &&
                                                <img src='/assets/img/swiggy.png' className='JointSocialLinkImage' />
                                            }
                                            <span>Order from {Contact.type}</span>
                                        </button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                {/* {
                    currentActive === number && */}
                    <div className={`CommenttsPopup ${ShowCommentsPop !== null ? 'show' : ''}`}>
                        <div className='BgColor' onClick={() => FunctionSetShowComments(null, false)} />
                        <DetailCardCommentSection
                            PostID={ShowCommentsPop}
                            Comments={ItemCurrent?.Comments}
                            setShowCommentsPop={FunctionSetShowComments}
                            enableInput={enableInput}
                            EnteredComment={EnteredComment}
                            setEnteredComment={setEnteredComment}
                            CommentOnPost={CommentOnPost}
                            DisabelSendbutton={DisabelSendbutton}
                            AddLikeDislikeComment={AddLikeDislikeComment}
                            LoadingComments={LoadingComments}

                            CurrentActiveComment={CurrentActiveComment}
                            setCurrentActiveComment={setCurrentActiveComment}
                            CurrentActiveCommentText={CurrentActiveCommentText}
                            setCurrentActiveCommentText={setCurrentActiveCommentText}

                            AddInternalComment={AddInterCommentOnDescussion}
                            LoadingInternalComments={LoadingInternalComments}
                            setLoadingInternalComments={setLoadingInternalComments}
                            setReportData={setReportData}

                            isLogin={isLogin}
                        />
                    </div>
                {/* // } */}

                <ReportModal
                setReportData={setReportData}
                ReportData={ReportData} />
            </div>
        </div>
    )
}
const mapStateToProps = ({ appToast, auth, post }) => {

    const {

    } = appToast

    const {
        Feeds
    } = post

    const {
        userCategory,
        userDeviceDetails,
        selectedAvatar,
        defaultAvatars,
        isLogin,
        userLocationDetails,
        CurrentFilters,
        userLocation,
        selectedCity,
        method
    } = auth

    return {
        userCategory,
        userDeviceDetails,
        selectedAvatar,
        defaultAvatars,
        isLogin,
        Feeds,
        userLocationDetails,
        CurrentFilters,
        userLocation,
        selectedCity,
        method
    }
}

const mapDispatchToProps = {
    setFeeds,
    setUserLocationDetails,
    getCurrentUserLocationAndSet,
    setSelectedCity
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodDetailsSharePage)
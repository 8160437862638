import React, { useEffect } from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router';
import { getCurrentUserLocation } from '../../features/auth/authSlice';
// import useGetSetLocationToLocal from '../../hooks/useGetSetLocationToLocal';

function WaitingScreen({ isLogin, getCurrentUserLocation, userLocation, userLocationDetails, selectedCity }) {
  const navigate = useNavigate();

  // const { getPlaceFromLocal, getPlaceLatLong, setPlaceToLocal, setPlaceLatLong } = useGetSetLocationToLocal();
  
  useEffect(() => {
    if (!isLogin) { 
      navigate('/login');
      return
    }
    console.log(isLogin, selectedCity, userLocationDetails)
    if (isLogin && selectedCity === null && userLocationDetails === null) {
      setTimeout(function() {
        navigate('/getLocation');
        return
      }, 1000);
    }

    if(isLogin && (selectedCity !== null || userLocationDetails !== null)) {
      // console.log(userLocation);
      setTimeout(function() {
        navigate('/FoodJointsNearby')
      }, 1000);
    }
  }, [isLogin, selectedCity, userLocationDetails])


  return (
    <div className='WaitingScreen TopBgImage'>
      <img src='assets/img/foodaspas.svg' alt='logo' />
      <h2 className='text-center mt-3 px-4 screenTitle'>Discovering your nearest food joints through vlogs</h2>
      <div className='spinnerLoader'></div>
    </div>
  )
}

const mapStateToProps = ({ appToast, auth }) => {
  const {

  } = appToast

  const {
    isLogin,
    userLocation,
    userLocationDetails,
    selectedCity
  } = auth

  return {
    isLogin,
    userLocation,
    userLocationDetails,
    selectedCity
  }
}

const mapDispatchToProps = {
  getCurrentUserLocation
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingScreen)
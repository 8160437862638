import React, { useState } from 'react';
import config from '../../config'
import { connect } from "react-redux"
import { setUserData, setSelectedCity } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router';

function SelectMainCity({
    userName,
    setUserData,
    isLogin,
    defaultMainCities,
    selectedCity,
    setSelectedCity
}) {
    const navigate = useNavigate();
    function changeSelected (itm) {
        setSelectedCity(itm)
        console.log(itm);
    }
    return (
        <div className='TopBgImage padding-2 height100vh'>
            <div className='ChooseMainCityPage'>
                <div className='TopSection'>
                    <h3>foodaspas</h3>
                </div>
                <div className='MiddleSection'>
                    <div className='SelectFromOptions'>
                        {
                            defaultMainCities.map((itm,i) => {
                                return (
                                    <div key={i} onClick={() => changeSelected(itm)}>
                                        <div className='avatarOption'>
                                            {
                                                selectedCity?.City === itm.City &&
                                                <div className='selectedOverlay'>
                                                    <ion-icon name="checkmark-outline" class="text-light fs-26"></ion-icon>
                                                </div>
                                            }
                                            <img src={itm.url} />
                                        </div>
                                        <p className={`text-center mb-0 fw-bold ${
                                                selectedCity?.City === itm.City && 'text-primary'}`}>{itm.City}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='BottomSection'>
                    
                    <button className='primaryThemeButtonTransparent' onClick={() => navigate('/RegisterYourName')}>
                        Go Back
                    </button>
                    {
                        isLogin ?
                        <button className='primaryThemeButton' onClick={() => navigate('/AreYouHungry')}>
                            Continue
                        </button>
                        :
                        <button className='primaryThemeButton' disabled onClick={() => navigate('/FoodJointsNearby')}>
                            Continue
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {

    } = appToast

    const {
        userName,
        isLogin,
        defaultMainCities,
        selectedCity
    } = auth

    return {
        userName,
        isLogin,
        defaultMainCities,
        selectedCity
    }
}

const mapDispatchToProps = {
    setUserData,
    setSelectedCity
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectMainCity)
import React from 'react'

function FoodJointCard() {
    return (
        <div className='FoodJointCard'>
            <img src='assets/img/foodjointcard.png' className='FoodJointCardImg' alt='Food Joint Card' />
            <div className='JointData'>
                <h4 className='JointDataTitle'>Malvan Tadka</h4>
                <h6 className='JointDataCategory'>Malvani, Kolhapuri and Konkani</h6>
                <div className='JointDataInfo'>
                    <span className='JointDataTime'>
                        <ion-icon name="time"></ion-icon>
                        11am to 11 pm
                    </span>
                    <span className='JointDataLocation'>
                        <ion-icon name="navigate"></ion-icon>
                        Belapur, Mumbai
                    </span>
                    <span className='JointDataStars'>
                        <ion-icon name="star"></ion-icon>
                        5
                    </span>
                </div>
                <div className='JointSocialLinks'>
                    <a href="#" className='JointSocialLink'>
                        <img src='assets/img/swiggy.png' className='JointSocialLinkImage' />
                    </a>

                    <a href="#" className='JointSocialLink'>
                        <img src='assets/img/zomato.svg' className='JointSocialLinkImage' />
                    </a>

                    <a href="#" className='JointSocialLink'>
                        <img src='assets/img/google.png' className='JointSocialLinkImage' />
                    </a>

                    <a href="#" className='JointSocialLink'>
                        <img src='assets/img/whatsapp.svg' className='JointSocialLinkImage' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FoodJointCard
export default function useGetSetLocationToLocal() {
    function getPlaceFromLocal() {
        const localPlace = localStorage.getItem('userLocalLocation');
        return localPlace
    }

    function getSelectedFromLocal() {
        const localPlace = localStorage.getItem('userSelectedLocation');
        return localPlace
    }

    function getPlaceLatLong() {
        const localLatLong = localStorage.getItem('userLocalLocationDetails');
        return localLatLong 
    }

    function setPlaceToLocal(place) {
        localStorage.setItem('userLocalLocation', place);
    }

    function setPlaceSelected(place) {
        localStorage.setItem('userSelectedLocation', place);
    }

    function setPlaceLatLong(latlong) {
        localStorage.setItem('userLocalLocationDetails', JSON.stringify(latlong));
    }


    function removePlaceToLocal() {
        localStorage.removeItem('userLocalLocation');
    }

    function removePlaceSelected() {
        localStorage.removeItem('userSelectedLocation');
    }

    function removePlaceLatLong(latlong) {
        localStorage.removeItem('userLocalLocationDetails');
    }

    return { 
        getPlaceFromLocal, 
        getPlaceLatLong, 
        getSelectedFromLocal,

        setPlaceToLocal, 
        setPlaceLatLong, 
        setPlaceSelected, 

        removePlaceToLocal,
        removePlaceSelected,
        removePlaceLatLong
     }
}
import React, { useEffect } from 'react'
import BackButton from './BackButton'
import { connect } from "react-redux";
import SvgIcon from '../../../svg/SvgIcon'
import { getCurrentUserLocation } from '../../../features/auth/authSlice';
import { useNavigate } from 'react-router';

function GetLocationShow({ userLocation, getCurrentUserLocation, selectedCity }) {
  const navigate = useNavigate()
    // function getCoordinates() {
    //     navigator.geolocation.getCurrentPosition(showPosition, showError);
    // }

    // function showPosition(position) {
    //     var latitude = position.coords.latitude;
    //     var longitude = position.coords.longitude;
    //     console.log("Latitude: " + latitude + "\nLongitude: " + longitude);
    //     getCurrentUserLocation({ 'latitude': latitude, 'longitude': longitude });
    // }

    // function getLocation() {
    //     if (navigator.permissions) {
    //       navigator.permissions.query({name:'geolocation'}).then(function(permissionStatus) {
    //         if (permissionStatus.state === 'granted') {
    //           getCoordinates();
    //         } else if (permissionStatus.state === 'prompt') {
    //           navigator.geolocation.getCurrentPosition(getCoordinates, showError);
    //         } else {
    //           console.log("You have denied permission for accessing geolocation.");
    //         }
    //       });
    //     } else {
    //       console.log("Your browser does not support navigator.permissions API.");
    //       getCoordinates();
    //     }
    // }

    // function showError(error) {
    //     switch(error.code) {
    //       case error.PERMISSION_DENIED:
    //         console.log("User denied the request for Geolocation.");
    //         break;
    //       case error.POSITION_UNAVAILABLE:
    //         console.log("Location information is unavailable.");
    //         break;
    //       case error.TIMEOUT:
    //         console.log("The request to get user location timed out.");
    //         break;
    //       case error.UNKNOWN_ERROR:
    //         console.log("An unknown error occurred.");
    //         break;
    //     }
    // }

    useEffect(() => {
        if(userLocation === null && selectedCity === null) {
          navigate('/loading')
            // if (navigator.geolocation) {
            //     getLocation();
            //   } else {
            //     console.log("Geolocation is not supported by this browser.");
            //   }
        }
    }, [userLocation])
  return (
    <>
        <BackButton className='px-0' LinkToGo='/getLocation'>
            <SvgIcon icon="mappin-fill" size="24" fill="#F55725" />
            {
                (userLocation === null && selectedCity === null) ?
                <>
                <span className='loader'></span>
                </>
                :
                <h5 className='fw-normal'>{selectedCity ? selectedCity : 'Your Location'}</h5>
            }
        </BackButton>
    </>
  )
}
const mapStateToProps = ({ auth }) => {

    const {
        userLocation,
        selectedCity
    } = auth
  
    return {
        userLocation,
        selectedCity
    }
  }
  
  const mapDispatchToProps = {
    getCurrentUserLocation
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(GetLocationShow)
import React from 'react'
import FoodJointCard from './Components/FoodJointCard'
import PageBottomNavigation from './Components/PageBottomNavigation'

function TopJointsNearYou() {
  return (
    <div className='height100vh bottomSpace'>
        <div className='TopFoodJointsPage padding-2 pb-0'>
            <div className='d-flex justify-content-between align-items-center'>
                <h5 className='sectionName mb-0'>Top Foodjoints near you</h5>
            </div>

            <div className='FoodJointsCardContainer'>
                {
                    [0,1,2,3,4,5,6,7,8,9,10,11,12].map((item) => {
                        return <FoodJointCard />
                    })
                }
            </div>
            <PageBottomNavigation page="TopRestaurantsNearYou" />
        </div>
    </div>
  )
}

export default TopJointsNearYou
import React, { useRef, useState, useEffect } from 'react'
import { Button, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import SvgIcon from '../../../svg/SvgIcon'
import axios from '../../../AxiosConfig'
import { connect } from "react-redux"
import { icon } from '@fortawesome/fontawesome-svg-core'


export const svgDots = () => {
    return (<Button variant='link'>
        <SvgIcon icon="dots" size="15" />
        </Button>)
}

export function AllComments({ 
    Comments = [], 
    LoadingComment, 
    AddLikeDislikeComment, 
    ParentCommentID, 
    selectedAvatar, 
    OnlyOneLevel,
    AddInternalComment,
    DisabelSendbutton,
    setReportData,
    PostID,
    setCurrentActiveComment,
    setCurrentActiveCommentText,
    CurrentActiveComment, 
    CurrentActiveCommentText,
    NoDrowerComponent,
    setShowCommentsPop,
    LoadingInternalComments,
    setLoadingInternalComments,
    InternalComment,
    item
 }) {

    return (
        <div className='AllCommentsSection'>
            {
                LoadingComment && !InternalComment &&
                <div className='commentCard LoadingComment'>
                    <span className='img animated-background' alt='userIcon' />
                    <div className='messageInfo'>
                        <p className='animated-background'></p>
                        <div className='LikeDislikeMessage'>
                            <span size='sm' variant='light' className='animated-background'>
                            </span>
                            <span size='sm' variant='light' className='animated-background'>
                            </span>
                        </div>
                    </div>
                    <div className='MoreActions ms-auto'>
                    </div>
                </div>
            }

            {
                LoadingInternalComments && InternalComment &&
                <div className='commentCard LoadingComment'>
                    <span className='img animated-background' alt='userIcon' />
                    <div className='messageInfo'>
                        <p className='animated-background'></p>
                        <div className='LikeDislikeMessage'>
                            <span size='sm' variant='light' className='animated-background'>
                            </span>
                            <span size='sm' variant='light' className='animated-background'>
                            </span>
                        </div>
                    </div>
                    <div className='MoreActions ms-auto'>
                    </div>
                </div>
            }
                {
                    Comments.map((item, i) => {
                        return (
                            <div className='commentCard' key={i}>
                                
                                                {/* <Button size='sm' variant='light' onClick={() => AddLikeDislikeComment(item, 'f', ParentCommentID)}>
                                                    {
                                                        item.userAction === 'f' ?
                                                        <SvgIcon icon='dislike-fill' className='LikedPost' size="18" />
                                                        :
                                                        <SvgIcon icon='dislike' size="18" />
                                                    }
                                                    {item.TotalDisLikes.toString() !== '0' && item.TotalDisLikes}
                                                </Button> */}

                                <img src={item.UserImage ? item.UserImage : 'https://yecc.sgp1.digitaloceanspaces.com/sampleUser.jpeg'} alt='userIcon' />
                                <div className='messageInfo'>
                                    <p>{item.CommentText}</p>
                                    {
                                        OnlyOneLevel &&
                                        <>
                                            <div className='LikeDislikeMessage'>
                                                <Button size='sm' variant='light' onClick={() => AddLikeDislikeComment(item, 't', ParentCommentID)}>
                                                    {
                                                        item.userAction === 't' ?
                                                        <SvgIcon icon='like-fill' className='LikedPost' size="18" />
                                                        :
                                                        <SvgIcon icon='like' size="18" />
                                                    }
                                                    {item.TotalLikes?.toString() !== '0' && item.TotalLikes}
                                                </Button>
                                                <Button size='sm' variant='light' onClick={() => setCurrentActiveComment ? setCurrentActiveComment((CurrentActiveComment === item.ID) ? null : item.ID) : setShowCommentsPop(PostID, false)}>
                                                    <SvgIcon icon='chat' size="18" />
                                                    {item.TotalReplies?.toString() !== '0' && item.TotalReplies}
                                                </Button>
                                            </div>
                                            {
                                                item && item.ID && CurrentActiveComment && (item.ID?.toString() === CurrentActiveComment?.toString()) &&
                                                <div className='AddInternalComment'>
                                                        <img src={selectedAvatar ? selectedAvatar : '/assets/img/userIcon1.png'} alt='user icon' />
                                                        <input type="text" value={CurrentActiveCommentText} onChange={(e) => setCurrentActiveCommentText(e.target.value)} placeholder='Add Comment' />
                                                        <button type="submit" disabled={DisabelSendbutton} onClick={() => AddInternalComment(item.ID)}>
                                                            <ion-icon name="send"></ion-icon>
                                                        </button>
                                                </div>
                                            }
                                            {
                                                item.Comments && item.Comments.length > 0 && CurrentActiveComment && CurrentActiveComment.toString() === item.ID.toString() &&
                                                <AllComments 
                                                ParentCommentID={CurrentActiveComment} 
                                                Comments={item.Comments} 
                                                LoadingComment={LoadingComment} 
                                                LikeDislikeComment={AddLikeDislikeComment} 
                                                setReportData={setReportData} 
                                                PostID={PostID}
                                                LoadingInternalComments={LoadingInternalComments}
                                                setLoadingInternalComments={setLoadingInternalComments}
                                                InternalComment />
                                            }
                                        </>
                                    }
                                </div>
                                <div className='MoreActions ms-auto'>
                                    <DropdownButton
                                        as={ButtonGroup}
                                        key='start'
                                        id={`dropdown-button-drop-start`}
                                        drop='start'
                                        variant="light"
                                        title={``}
                                        >
                                        <Dropdown.Item eventKey="1" onClick={() => setReportData({ 'type':  'CommentReport', 'data' : item, 'Post_ID' : PostID })}>Report</Dropdown.Item>
                                    </DropdownButton>
                                    {/* <Button size='sm' variant='light'>
                                        <SvgIcon icon='dots' size="20" />
                                    </Button> */}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
    )
}

function DetailCardCommentSection({ 
    number, 
    setShowCommentsPop, 
    clickToShowcomments, 
    Comments, 
    PostID, 
    selectedAvatar, 
    AddCommentToList, 
    LoadingComments, 
    setLoadingComments,
    setReportData, 
    enableInput, 
    NoDrowerComponent, 
    ActiveShowCommentsPop, 
    ReloadedComments,
    EnteredComment,
    setEnteredComment,
    CommentOnPost,
    DisabelSendbutton,
    AddLikeDislikeComment,

    CurrentActiveComment,
    setCurrentActiveComment,
    CurrentActiveCommentText,
    setCurrentActiveCommentText,

    AddInternalComment,

    LoadingInternalComments,
    setLoadingInternalComments,

    isLogin,
    item
 }) {
    const inputRef = useRef();

    useEffect(() => {
        if(inputRef.current && enableInput) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 1000);
        }
    }, [inputRef, enableInput]);

  return (
    <div className='CommentsSectionPage'>
        <div className='CommentsSection'>
            <div className='FoodTitleSection Alternate'>
                <button className='btn btn-link' 
                    onClick={() => setShowCommentsPop(null, false)}
                >
                    <SvgIcon icon='arrowLeft' size="22" />
                </button>
                <h4>Discussions</h4>
            </div>
            <div className={`AddCommentSection px-2`}>
                {/* {clickToShowcomments && 
                    <span className='NotClickable' onClick={() => setShowCommentsPop(PostID)} />
                } */}
                <div className='inputContainer'>
                    {
                        !CommentOnPost &&
                        <span className='clickToOpenDiscussion' 
                        onClick={() => setShowCommentsPop(PostID, true)} 
                        />
                    }
                    <img src={selectedAvatar ? selectedAvatar : '/assets/img/userIcon1.png'} alt='user icon' />
                    <input 
                        type="text" 
                        ref={inputRef} 
                        value={EnteredComment} 
                        onChange={(e) => setEnteredComment(e.target.value)} 
                        placeholder='Start Discussion'
                        disabled={!isLogin}
                    />
                    <button type="submit"
                        disabled={DisabelSendbutton || !isLogin} 
                        onClick={CommentOnPost}
                    >
                        <ion-icon name="send"></ion-icon>
                    </button>
                </div>
            </div>
            <AllComments 
                selectedAvatar={selectedAvatar}
                PostID={PostID}
                LoadingComment={LoadingComments} 
                Comments={Comments} 
                OnlyOneLevel
                AddLikeDislikeComment={AddLikeDislikeComment} 

                CurrentActiveComment={CurrentActiveComment} 
                setCurrentActiveComment={setCurrentActiveComment} 
                CurrentActiveCommentText={CurrentActiveCommentText}
                setCurrentActiveCommentText={setCurrentActiveCommentText}

                DisabelSendbutton={DisabelSendbutton}
                setShowCommentsPop={setShowCommentsPop}
                AddInternalComment={AddInternalComment}

                LoadingInternalComments={LoadingInternalComments}
                setLoadingInternalComments={setLoadingInternalComments}
                setReportData={setReportData}

                item={item}
                // NoDrowerComponent={NoDrowerComponent}
            />
        </div>
    </div>
  )
}
const mapStateToProps = ({ appToast, auth }) => {
    const {
  
    } = appToast
  
    const {
        selectedAvatar
    } = auth
  
    return {
        selectedAvatar
    }
  }
  
  const mapDispatchToProps = {
    
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(DetailCardCommentSection)
import React from 'react'
import { Link } from 'react-router-dom'

function PageBottomNavigation({ page }) {
  return (
    <div className='BottomNavigationButtons'>

        <Link to="/FoodJointsNearby" className={`BottomButtons${page === 'FeedsPage' ? ' active' : ''}`}>
            <ion-icon name="albums"></ion-icon>
            <span>Feed</span>
        </Link>

        <Link to="/TopJointsNearYou" className={`BottomButtons${page === 'TopRestaurantsNearYou' ? ' active' : ''}`}>
            <ion-icon name="fast-food"></ion-icon>
            <span>Food</span>
        </Link>

        <Link to="/Profile" className={`BottomButtons${page === 'ProfilePage' ? ' active' : ''}`}>
            <ion-icon name="person-circle"></ion-icon>
            <span>You</span>
        </Link>
    </div>
  )
}

export default PageBottomNavigation
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router';
import { connect } from "react-redux"
import { setUserData } from '../../features/auth/authSlice';
import { getAreas, getCurrentUserLocation, setSelectedCity, setCurrentPrefrences, setUserLocationDetails, setPostMethod, getCurrentUserLocationAndSet } from '../../features/auth/authSlice';
import SvgIcon from '../../svg/SvgIcon';
import config from '../../config';
import axios from '../../AxiosConfig'
import useGetSetLocationToLocal from '../../hooks/useGetSetLocationToLocal';

function GetSetLocation({ 
  userLocation,
  userLocationDetails,
  getAreas,
  setUserData,
  getCurrentUserLocation,
  getCurrentUserLocationAndSet,
  setSelectedCity,
  setCurrentPrefrences,
  setUserLocationDetails,
  setPostMethod,
  selectedCity
}) {
  const navigate = useNavigate();

  const { getPlaceFromLocal, getPlaceLatLong, setPlaceToLocal, setPlaceLatLong, setPlaceSelected, removePlaceSelected, removePlaceToLocal, removePlaceLatLong } = useGetSetLocationToLocal();

  const [showAnimation, setShowAnimation] = useState(false);

  const [inputError, setInputError] = useState(null);

  const [showList, setShowList] = useState(false);
  const timeoutRef = useRef(null);
  const handleFocus = () => {
    setShowList(true);
  };

  const handleBlur = () => {
    timeoutRef.current = setTimeout(() => {
      setShowList(false);
    }, 200);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const [TypeArea, setTypeArea] = useState('');
  const [Areas, setAreas] = useState(null);

  // const changeUserLocation = (e) => {
  //   setUserData({ 'userLocation' : e.target.value })
  //   localStorage.setItem('userLocation', e.target.value);
  // }

  // function DetectLoactionAnimation() {
  //   setShowAnimation(true);
  
  //   setTimeout(() => {
  //     setShowAnimation(false);
  //   }, 5000);
  // }

  useEffect(async function () {
    if(Areas === null) {
      const areaValues = await axios.get(config.apiUrl + '/posts/getApiFilters/Area');
      console.log(areaValues?.data?.data?.Area);
      if(areaValues?.data?.data?.Area) {
        setAreas(areaValues.data.data.Area);
      }
    }
  }, [Areas])

  function reorderStations(stations, typedText) {
    const lowerText = typedText.toLowerCase();
    const matchingStations = stations.filter(station => station.toLowerCase().startsWith(lowerText));
    const remainingStations = stations.filter(station => !matchingStations.includes(station));
    return [...matchingStations, ...remainingStations];
  }

  useEffect(() => {
    if(Areas) {
      const newArea = reorderStations(Areas, TypeArea);
      console.log(newArea);
      setAreas(newArea);
    }
  }, [TypeArea])

  useEffect(() => {
    if(inputError) {
      if(Areas.filter((itm) => itm === TypeArea).length > 0) {
        setInputError(null);
      }
    }
  }, [inputError, TypeArea])
  
  function StartDiscovering() {
    if(TypeArea === '') { 
      document.getElementById('area').focus();
    } else if (Areas.filter((itm) => itm === TypeArea).length === 0) {
      setInputError('Please select value from dropdown');
      // setvalidation(true)
    } else {
      setInputError(null);
      setSelectedCity(TypeArea);
      setPlaceSelected(TypeArea);
      // const AreaSelected = {
      //   'Area' : [TypeArea]
      // }
      // setCurrentPrefrences(AreaSelected);
      setPostMethod('static');
      navigate('/FoodJointsNearby');
      setCurrentPrefrences(null);
      // setvalidation(false)
    }
    // || Areas === null || Areas.filter((itm) => itm === TypeArea).length === 0
  }

  useEffect(() => {
    if(selectedCity) {
      setTypeArea(selectedCity);
    }
  }, [selectedCity])
  
  const [DetectingLocation, setDetectingLocation] = useState(false);

  
  function getCoordinates() {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
  }
  
  async function showPosition(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    // console.log("Latitude: " + latitude + "\nLongitude: " + longitude);
    const isDone = await getCurrentUserLocation({ 'latitude': latitude, 'longitude': longitude });
    console.log(isDone);
    setDetectingLocation(false);
    if(isDone?.payload?.results) {
      setCurrentPrefrences(null);
      removePlaceSelected();
      // setUserLocationDetails(null);
      setPostMethod('dynamic');
      setSelectedCity(null);
      navigate('/FoodJointsNearby')
    }

  }
  
  function getLocation() {
    if(userLocationDetails && userLocation) {
      setCurrentPrefrences(null);
      removePlaceSelected();
      // setUserLocationDetails(null);
      setPostMethod('dynamic');
      navigate('/FoodJointsNearby');
      setSelectedCity(null);
      return
    }


    setDetectingLocation(true);
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
        console.log(permissionStatus)
        if (permissionStatus.state === 'granted') {
          getCoordinates();
        } else if (permissionStatus.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(getCoordinates, showError);
        } else {
          console.log("You have denied permission for accessing geolocation.");
          setDetectingLocation(false);
        }
      });
    } else {
      console.log("Your browser does not support navigator.permissions API.");
      getCoordinates();
    }
  }
  
  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
    }
  }

  async function setOnlyLatAndLong(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    // const data = {
    //   lat : latitude,
    //   long : longitude
    // }
    // setPlaceLatLong(data);
    // setUserLocationDetails(data);
    const isDone = await getCurrentUserLocationAndSet({ 'latitude': latitude, 'longitude': longitude });
    console.log(isDone)
  }

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
        // console.log(permissionStatus)
        if (permissionStatus.state === 'granted') {
          navigator.geolocation.getCurrentPosition(setOnlyLatAndLong);
        } else if (permissionStatus.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(setOnlyLatAndLong);
        } else {
          console.log("You have denied permission for accessing geolocation.");
          // setDetectingLocation(false);
        }
      });
    }
  }, [navigator])
  
  return (
    <div className='TopBgImage padding-2 height100vh'>
      <div className='GetLocationPage'>
          <div className='TopSection'>
            <h3>foodaspas</h3>
          </div>

          <div className={`MiddleSection ${showAnimation && 'Loading'}`}>
            <div className='titleHolder'>
              <h5 className='mt-1 d-flex align-items-center gap-2 location'>
                <SvgIcon icon='mappinLine' size="23" />
                Mumbai
              </h5>
              <p className='text-center text-grey mb-0'>We are coming to other cities soon</p>
            </div>

            <div className={`form-group AreaSearch mt-5  ${inputError && 'invalid'}`}>
              {inputError && <div class="invalid-feedback text-center">{inputError}</div>}
              <SvgIcon icon='caretCircleDown-fill' fill='#767676' />
              <input
              name="area"
              value={TypeArea}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => setTypeArea(e.target.value)}
              id="area"
              autoComplete='off'
              placeholder='Search or Select Area' />
              {
                Areas &&
                <ul className='areasToSearch' style={{ display: showList ? 'block' : 'none' }}>
                  {
                    Areas.map((are, i) => {
                      return <>
                      {
                        // (are.toString().includes(TypeArea) || TypeArea === '') &&
                        <li className={TypeArea.toString().trim().length > 0 && are.toString().includes(TypeArea) && 'bg-match'} key={i}><button onClick={() => setTypeArea(are)}>{are}</button></li>
                      }
                      </>
                    })
                  }
                </ul>
              }
            </div>

            <button className='LocationSet primaryThemeButton mt-3' disabled={Areas === null} onClick={StartDiscovering}>
              Start Discovering
              </button>
            {/* <input type='text' className='cityLocation' value={userLocation} onChange={changeUserLocation} /> */}
            {/* <div className='LocationContainer'>
              <div className='RadialGradient'>
              </div>
              <div className='pin'>
                <SvgIcon icon='mappin-fill' size="40" />
              </div>
            </div>
            <span className='mt-3'>Food joints Nearby</span>
            <h5 className='mt-1 d-flex align-items-center gap-2 location'>
              <SvgIcon icon='mappinLine' size="23" />
              {userLocation}
            </h5>
            <h5 className='mt-1 d-flex align-items-center gap-2 detecting'>
              <SvgIcon icon='mappinLine' size="23" />Detecting...
            </h5> */}
          </div>

          <div className='BottomSection'>
            {/* <button className='LocationSet primaryThemeButton' disabled={TypeArea === '' || Areas === null || Areas.filter((itm) => itm === TypeArea).length === 0}>
              Start Discovering
              </button> */}
              <button className='getUserLocation primaryThemeButtonOutline d-flex gap-1' disabled={DetectingLocation} onClick={getLocation}>
                Detect Location
                {
                  DetectingLocation &&
                  <div className='spinnerLoader' style={{'width': '25px'}}></div>
                }
              </button>
          </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ appToast, auth }) => {
  const {} = appToast

  const {
    userLocation,
    selectedCity,
    userLocationDetails
  } = auth

  return {
    userLocation,
    selectedCity,
    userLocationDetails
  }
}

const mapDispatchToProps = {
  setUserData,
  getAreas,
  getCurrentUserLocation,
  getCurrentUserLocationAndSet,
  setSelectedCity,
  setCurrentPrefrences,
  setUserLocationDetails,
  setPostMethod
}

export default connect(mapStateToProps, mapDispatchToProps)(GetSetLocation)
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { connect } from "react-redux"
import { setUserData } from '../../features/auth/authSlice'

const GetSelectCategories = ({
    userCategory,
    setUserData
}) => {
    const navigate = useNavigate()
    const LocalCategory = [
        { 'name' : 'Konkani' },
        { 'name' : 'Malvani' },
        { 'name' : 'Kolhapuri' },
        { 'name' : 'Punjabi' },
        { 'name' : 'Kashmiri' },
        { 'name' : 'Rajasthani' },
    ]
    const InternationalCategory = [
        { 'name' : 'Italian' },
        { 'name' : 'Mexican' },
        { 'name' : 'Japanese' },
        { 'name' : 'Indian' },
        { 'name' : 'Thai' },
        { 'name' : 'French' },
        { 'name' : 'Greek' },
        { 'name' : 'Spanish' },
        { 'name' : 'Moroccan' },
        { 'name' : 'Ethiopian' },
        { 'name' : 'Vietnamese' },
        { 'name' : 'Peruvian' },
        { 'name' : 'Korean' },
        { 'name' : 'Brazilian' },
        { 'name' : 'Caribbean' },
    ]

    const checkedFoodCategory = (value) => {
        // setSelectedCategories(prevState => )
        let cates
        if(!userCategory.includes(value)) {
            cates = [...userCategory, value];
        } else {
            cates = userCategory.filter(cat => cat !== value);
        }
        setUserData({ 'userCategory' : cates })
        localStorage.setItem('userCategory', JSON.stringify(cates));
        // if(e.target.checked) {
        // }
        console.log(value)
    }
    
  return (
    <div className='TopBgImage padding-2 height100vh'>
        <div className='SelectCategoryPage'>
            <div className='TopSection'>
                <h3>foodaspas</h3>
            </div>
            <div className='CategoriesSection'>
                <div className='PopularCategoriesSection my-3'>
                    {
                        LocalCategory.map((itm, i) => {
                            return (
                                <div key={i} className={`CategoryItem ${userCategory.includes(itm.name) ? 'selectedCat' : ''}`}  onClick={() => checkedFoodCategory(itm.name)}>
                                    {/* <input type='checkbox' checked={userCategory.includes(itm.name)} className='selectCheckBox' name={itm.name} value={itm.name} /> */}
                                    <div className='CategoryItemImageWrapper isSelected'>
                                        <img src='assets/img/food_placeholder.jpeg' placeholder='Food Image' />
                                        <div className='selectedOverlay'>
                                            <ion-icon name="checkmark-outline" class="text-light fs-26"></ion-icon>
                                        </div>
                                    </div>
                                    <span>{itm.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <h4 className='text-center py-4 cusineYouWant'>Which cuisine you want to see?</h4>
                <div className='allCategoriesSection'>
                    {
                        InternationalCategory.map((itm, i) => {
                            return (
                                <div key={i} className={`CategoryItem ${userCategory.includes(itm.name) ? 'selectedCat' : ''}`}  onClick={() => checkedFoodCategory(itm.name)}>
                                    {/* <input type='checkbox' checked={userCategory.includes(itm.name)} className='selectCheckBox' name={itm.name} value={itm.name} /> */}
                                    <div className='CategoryItemImageWrapper isSelected'>
                                        <img src='assets/img/food_placeholder.jpeg' placeholder='Food Image' />
                                        <div className='selectedOverlay'>
                                            <ion-icon name="checkmark-outline" class="text-light fs-26"></ion-icon>
                                        </div>
                                    </div>
                                    <span>{itm.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='BottomSection'>
                <button className='LocationSet primaryThemeButton' onClick={() => navigate('/RegisterYourName')} disabled={userCategory.length === 0}>
                    Next
                </button>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {

    } = appToast

    const {
        userCategory
    } = auth

    return {
        userCategory
    }
}

const mapDispatchToProps = {
    setUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(GetSelectCategories)

import React, { useState } from 'react';
import config from '../../config'
import { connect } from "react-redux"
import { setUserData, setSelectedCity } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router';

function AreYouHungry({
    userName,
    setUserData,
    isLogin
}) {
    const navigate = useNavigate();
    function changeSelected (itm) {
        setSelectedCity(itm)
        console.log(itm);
    }
    return (
        <div className='TopBgImage padding-2 height100vh'>
            <div className='ChooseMainCityPage HungryPage'>
                <div className='TopSection'>
                    <h3>foodaspas</h3>
                </div>
                <div className='MiddleSection'>
                    <img src='assets/img/Hungry.png' alt='Hungry?' />
                    <div>
                        <h4 className='text-center'>Hungry?</h4>
                        <p className='text-center'>Don't keep your stomach waiting! Find the perfect food joint to satisfy your cravings with Foodaspas.</p>
                    </div>
                </div>

                <div className='BottomSection'>
                    
                    {/* <button className='primaryThemeButtonTransparent' onClick={() => navigate('/RegisterYourName')}>
                        Go Back
                    </button> */}
                        <button className='primaryThemeButton' onClick={() => navigate('/getLocation')}>
                            Continue
                        </button>
                    {/* {
                        isLogin ?
                        :
                        <button className='primaryThemeButton' disabled onClick={() => navigate('/FoodJointsNearby')}>
                            Continue
                        </button>
                    } */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {

    } = appToast

    const {
        userName,
        isLogin,
        defaultMainCities,
        selectedCity
    } = auth

    return {
        userName,
        isLogin,
        defaultMainCities,
        selectedCity
    }
}

const mapDispatchToProps = {
    setUserData,
    setSelectedCity
}

export default connect(mapStateToProps, mapDispatchToProps)(AreYouHungry)
import React, { useState, useEffect } from 'react'
import InputOTP from './Components/InputOTP';
import { connect } from "react-redux";
import { useNavigate } from 'react-router';
import { requestOtp, validateOtp } from '../../features/auth/authSlice';

function RegisterYourNumber({ 
    requestOtp,
    showVerifyOtpForm,
    validateOtp,
    otpValidateError
 }) {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState(true);

    const [isValidationActive, setValidationActive] = useState(false);

    const [disableSendButton, setDisableSendButton] = useState(false);
    const [disableVerifyButton, setDisableVerifyButton] = useState(false);


    const [Errors, setErrors] = useState({
        'Mobile': null,
        'OTP' : null
    });


    const [EnterMobile, setEnterMobile] = useState(null);
    const [EnteredOTP, setEnteredOTP] = useState(null);

    function GetOTP(data) {
        console.log(data);
    }
    function changedMobile(e) {
        const enterValue = e.target.value;
        setEnterMobile(enterValue);

        if(isValidationActive) {
            const tempError = JSON.parse(JSON.stringify(Errors));
            if(enterValue.toString().trim().length < 10 || enterValue.toString().trim().length > 10) {
                tempError['Mobile'] = 'Please enter 10 digit Mobile Number';
            } else  {
                tempError['Mobile'] = null;
            }
            setErrors(tempError);
        }
    }

    function ChangeOTP(e) {
        const enterValue = e.target.value;
        setEnteredOTP(enterValue);

        if(isValidationActive) {
            const tempError = JSON.parse(JSON.stringify(Errors));
            if(enterValue.toString().trim().length < 6 || enterValue.toString().trim().length > 6) {
                tempError['OTP'] = 'Please enter 6 digit OTP';
            } else  {
                tempError['OTP'] = null;
            }
            setErrors(tempError);
        }
    }

    function SubmitMobile(arg) {

        const tempError = JSON.parse(JSON.stringify(Errors));
        if(mobile || arg) {
            if(EnterMobile === null) {
                tempError['Mobile'] = 'Please enter mobile number';
                setErrors(tempError);
                setValidationActive(true);
                return
            } else if(EnterMobile.toString().trim().length < 10 || EnterMobile.toString().trim().length > 10) {
                tempError['Mobile'] = 'Please enter 10 digit mobile number';
                setErrors(tempError);
                setValidationActive(true);
                return 
            }
            setDisableSendButton(true);
            requestOtp({mobileNumber : EnterMobile});
            tempError['Mobile'] = null;
            setErrors(tempError);
            setValidationActive(false);
            setMobile(false);
        } else {
            if(EnteredOTP.toString().trim().length !== 6) {
                tempError['OTP'] = 'Please enter 6 digit OTP';
                setErrors(tempError);
                setValidationActive(true);
                return 
            }
            setDisableVerifyButton(true);
            validateOtp({mobileNumber : EnterMobile, otp : EnteredOTP });
            tempError['OTP'] = null;
            setErrors(tempError);
            setValidationActive(false);
        }
        console.log(mobile)
    }

    useEffect(() => {
      if(showVerifyOtpForm) {
        setDisableSendButton(false);
        setMobile(false);
      }

      if(otpValidateError) {
        const tempError = JSON.parse(JSON.stringify(Errors));
        setDisableVerifyButton(false);
        tempError['OTP'] = 'Please enter correct OTP';
        setErrors(tempError);
      }
    }, [showVerifyOtpForm, otpValidateError])
    
    return (
        <div className='TopBgImage padding-2 height100vh'>
            <div className='RegisterYourNumberPage'>
                <div className='TopSection'>
                    <h3>foodaspas</h3>
                </div>
                <div className='MiddleSection'>
                    <img src='assets/img/NumberPage.svg' alt='Enter Your Number' />
                    <h2 className='text-center screenTitle'>Be a part of India's most engaging food community</h2>
                </div>

                <div className='BottomSection'>
                    {
                        mobile ?
                        <div class={`mobileinput-group ${Errors.Mobile && 'invalid'}`}>
                            {/* <select class="form-control form-select" id="countryCode">
                                <option value="+91">+91</option>
                                <option value="+92">+92</option>
                                <option value="+93">+93</option>
                                <option value="+94">+94</option>
                            </select> */}
                            <input type="number" key='one' className="form-control phoneNumber text-center"  value={EnterMobile} onChange={changedMobile} placeholder='Enter Your Number' name='phone' aria-label="" />
                            {Errors.Mobile && <div class="invalid-feedback">{Errors.Mobile}</div>}
                        </div>
                        :
                        <div className={`OtpValidation  ${Errors.OTP && 'invalid'}`}>
                            {/* <InputOTP OTPlength='6' getOTPtoValidate={GetOTP} /> */}
                            <input type="number" autoFocus key='two' className="form-control"  value={EnteredOTP} onChange={ChangeOTP} placeholder='******' name='OTP' aria-label="" />
                            {Errors.OTP && <div class="invalid-feedback text-center">{Errors.OTP}</div>}
                        </div>
                    }

                    {
                        mobile ?
                        <button className='primaryThemeButton' disabled={disableSendButton} onClick={() => SubmitMobile()}>
                            Send OTP
                        </button>
                        :
                        <>
                        <div className='d-flex gap-1'>
                            <button
                            disabled={disableSendButton}
                            className='primaryThemeButtonOutline'
                            onClick={() => SubmitMobile(true)}>
                                Resend
                            </button>

                            <button
                                className='primaryThemeButton'
                                disabled={disableVerifyButton}
                                // onClick={() => navigate('/RegisterYourName')}
                                onClick={() => SubmitMobile()}>
                                    Submit
                                </button>
                            </div>
                            {/* <button className='primaryThemeButtonTransparent'  onClick={() => setMobile(prevState => !prevState)}>
                                Go Back
                            </button> */}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {

    } = appToast

    const {
        showVerifyOtpForm,
        otpValidateError
    } = auth

    return {
        showVerifyOtpForm,
        otpValidateError
    }
}

const mapDispatchToProps = {
    requestOtp,
    validateOtp
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterYourNumber)
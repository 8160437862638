import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import authReducer from '../features/auth/authSlice'
import PostReducer from '../features/postApi/postSlice'

export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    post: PostReducer
  },
});
import React, { useState } from 'react';
import config from '../../config'
import { connect } from "react-redux"
import { setUserData, setSelectedAvatar } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router';

function ChooseAvatar({
    userName,
    setUserData,
    isLogin,
    defaultAvatars,
    selectedAvatar,
    setSelectedAvatar
}) {
    const navigate = useNavigate();
    function changeSelected (itm) {
        setSelectedAvatar(itm)
    }
    return (
        <div className='TopBgImage padding-2 height100vh'>
            <div className='RegisterChooseAvatarPage'>
                <div className='TopSection'>
                    <h3>foodaspas</h3>
                </div>
                <div className='MiddleSection'>
                    <div className='DefaultAvatar'>
                        <img src={selectedAvatar ? selectedAvatar.url : 'assets/img/sample/avatar/defaultAvatar.png'} />
                    </div>
                    <h4 className='text-center'>Choose Your Avatar</h4>
                    <div className='SelectFromOptions'>
                        {
                            defaultAvatars.map((itm,i) => {
                                return (
                                    <div className='avatarOption' key={i} onClick={() => changeSelected(itm)}>
                                        {
                                            selectedAvatar?.id === itm.id &&
                                            <div className='selectedOverlay'>
                                                <ion-icon name="checkmark-outline" class="text-light fs-26"></ion-icon>
                                            </div>
                                        }
                                        <img src={itm.url} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='BottomSection'>
                    
                    <button className='primaryThemeButtonTransparent' onClick={() => navigate('/RegisterYourName')}>
                        Go Back
                    </button>
                    {
                        isLogin ?
                        <button className='primaryThemeButton' onClick={() => navigate('/ChooseCity')}>
                            Continue
                        </button>
                        :
                        <button className='primaryThemeButton' disabled onClick={() => navigate('/FoodJointsNearby')}>
                            Continue
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {

    } = appToast

    const {
        userName,
        isLogin,
        defaultAvatars,
        selectedAvatar
    } = auth

    return {
        userName,
        isLogin,
        defaultAvatars,
        selectedAvatar
    }
}

const mapDispatchToProps = {
    setUserData,
    setSelectedAvatar
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAvatar)
import React from 'react'
import SvgIcon from '../../../svg/SvgIcon'

function FoodLoadingCard() {
  return (
    <div className='FoodDetailCard FoodLoadingCard'>
        <div className='FoodDetailCard_Top animated-backgroundAll'>

        </div>

        <div className='FoodDetailCard_Bottom'>
            <div className='VideoOwnerDetails'>
                {/* <img src={selectedAvatar ? selectedAvatar : defaultAvatars[1].url} placeholder='profile photo' style={{'width': '20px'}} /> */}
                {/* <SvgIcon icon='userCircle-fill' size="18" fill='#000000' /> */}
                <h6 className='px-1 py-0 animated-backgroundAll' style={{'height' : '14px', 'width' : '100px'}}></h6>
            </div>
            <div className='FoodDetailCard_RestaurantDetails'>
                <div className='leftDetails'>
                    <h4 className='animated-backgroundAll' style={{'height' : '17px', 'width' : '70%'}}></h4>
                    <div className='JointDataInfo'>
                        <span className='JointLocation animated-backgroundAll d-flex justify-content-start' style={{'width': "70px", "height": '16px'}}>
                            {/* <SvgIcon icon='mappin-fill' size="14" fill='#767676' /> */}

                        </span>
                        <span className='JointDataTime animated-backgroundAll d-flex justify-content-start' style={{'width': "70px", "height": '16px'}}>
                            {/* <ion-icon name="time"></ion-icon> */}
                            
                        </span>
                        <span className='JointDataLocation animated-backgroundAll d-flex justify-content-start' style={{'width': "70px", "height": '16px'}}>
                            {/* <SvgIcon icon='wallet-fill' size="14" fill='#767676' /> */}
                            
                        </span>
                    </div>
                </div>
                <div className='centerDetails'>
                    <button className='contactDetails animated-backgroundAll border-none' style={{'height': '40px', 'border': 'none'}}>
                        <span>
                            {/* Contact */}
                        </span>
                        {/* <SvgIcon icon='call-fill' size="18" fill='#F55725' /> */}
                    </button>
                    <button className='contactDetails animated-backgroundAll border-none' style={{'height': '40px', 'border': 'none'}}>
                        <span>
                            {/* Order */}
                        </span>
                        {/* <SvgIcon icon='notebook-fill' size="18" fill='#F55725' /> */}
                    </button>
                    <button className='contactDetails animated-backgroundAll border-none' style={{'height': '40px', 'border': 'none'}}>
                        <span>
                        {/* Visit */}
                        </span>
                        {/* <SvgIcon icon='mappin-fill' size="18" fill='#F55725' /> */}
                    </button>
                </div>

            </div>
            <div className='FoodDetailCard_Questions mt-1'>
                
                <div className='inputContainer animated-backgroundAll'>

                </div>
            </div>
        </div>

        <div className='FoodDetailCard_Action'>
            <button className='btn btn-sm'>
                <span className='animated-backgroundAll w-100' style={{'height' : '40px', 'borderRadius' : '6px'}}>

                </span>
                {/* {
                    ItemCurrent.userAction === 't' ?
                    <SvgIcon icon='heart-fill' className='LikedPost' size="18" />
                    :
                    <SvgIcon icon='heart' size="18" />
                }
                {ItemCurrent.NumberOfLikes} */}
            </button>

            <button className='btn btn-sm'>
            <span className='animated-backgroundAll w-100' style={{'height' : '40px', 'borderRadius' : '6px'}}>

</span>
                {/* {
                    ItemCurrent.bookmark === 't' ?
                    <SvgIcon icon='bookmark-fill' size="18" />
                    :
                    <SvgIcon icon='bookmark' size="18" />
                } */}
            </button>

            <button className='btn btn-sm'>
            <span className='animated-backgroundAll w-100' style={{'height' : '40px', 'borderRadius' : '6px'}}>

</span>
                {/* <SvgIcon icon='share-fill' size="18" /> */}
            </button>

            <button className='btn btn-sm'>
            <span className='animated-backgroundAll w-100' style={{'height' : '40px', 'borderRadius' : '6px'}}>

</span>
                {/* <SvgIcon icon='warning-fill' size="18" /> */}
            </button>
        </div>
    </div>
  )
}

export default FoodLoadingCard
import { useState } from "react";

export default function useDetectLocationInBackground () {
    const [DetectingLocation, setDetectingLocation] = useState(false);

    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);

    const [Error, setError] = useState(null);

    function showError(error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setError("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            setError("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            setError("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
            setError("An unknown error occurred.");
            break;
          default: 
            setError("An unknown error occurred.");
            break;
        }
    }

    function getCoordinates() {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
    }

    async function showPosition(position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        setLat(latitude);
        setLong(longitude);
        const data = {"lat":latitude,"long":longitude}
        // console.log("Latitude: " + latitude + "\nLongitude: " + longitude);
        // const isDone = await getCurrentUserLocation({ 'latitude': latitude, 'longitude': longitude });
        // setDetectingLocation(false);
        // if(isDone?.payload?.results) {
        //   setCurrentPrefrences(null);
          
          // setUserLocationDetails(null);
        //   navigate('/FoodJointsNearby')
        // }
    
    }

    function getLocation() {
        setDetectingLocation(true);
        if (navigator.permissions) {
          navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
            console.log(permissionStatus)
            if (permissionStatus.state === 'granted') {
              getCoordinates();
            } else if (permissionStatus.state === 'prompt') {
              navigator.geolocation.getCurrentPosition(getCoordinates, showError);
            } else {
              console.log("You have denied permission for accessing geolocation.");
              setDetectingLocation(false);
            }
          });
        } else {
          console.log("Your browser does not support navigator.permissions API.");
          getCoordinates();
        }
    }

    return { DetectingLocation, lat, long, getLocation, Error }
}
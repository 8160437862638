import React from 'react'
import SvgIcon from '../../../svg/SvgIcon'
import { useNavigate } from 'react-router'

function Posts({ item }) {
  const navigate = useNavigate();
  return (
    <div className='SavedPost' onClick={() => navigate(`/FoodJointsNearby/bookmark-${item.Post_ID}`)}>
        <img src='assets/img/pizzaPost.jpeg' alt='Post Image' />
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
        <path d="M8.54807 48.1401C5.87378 45.5572 3.74068 42.4675 2.27323 39.0514C0.805778 35.6353 0.033364 31.9611 0.00105719 28.2433C-0.0312497 24.5255 0.677198 20.8385 2.08506 17.3974C3.49293 13.9563 5.57201 10.83 8.20101 8.20101C10.83 5.57201 13.9563 3.49293 17.3974 2.08506C20.8385 0.677198 24.5255 -0.0312497 28.2433 0.00105719C31.9611 0.033364 35.6353 0.805778 39.0514 2.27323C42.4675 3.74068 45.5572 5.87378 48.1401 8.54807C53.2405 13.8289 56.0627 20.9018 55.9989 28.2433C55.9351 35.5848 52.9904 42.6076 47.799 47.799C42.6076 52.9904 35.5848 55.9351 28.2433 55.9989C20.9018 56.0627 13.8289 53.2405 8.54807 48.1401ZM44.1921 44.1921C48.3952 39.9889 50.7565 34.2882 50.7565 28.3441C50.7565 22.3999 48.3952 16.6992 44.1921 12.4961C39.9889 8.29292 34.2882 5.93161 28.3441 5.93161C22.3999 5.93161 16.6992 8.29292 12.4961 12.4961C8.29292 16.6992 5.93161 22.3999 5.93161 28.3441C5.93161 34.2882 8.29292 39.9889 12.4961 44.1921C16.6992 48.3952 22.3999 50.7565 28.3441 50.7565C34.2882 50.7565 39.9889 48.3952 44.1921 44.1921ZM19.9441 17.1441L42.3441 28.3441L19.9441 39.5441V17.1441Z" fill="#EBEBEB" fill-opacity="0.88"/>
        </svg> */}
        <div className='VideoOwner'>
          by <span>{item.YoutubeChannelName}</span>
        </div>
        <h3 className='PostTitle'>{item.BussinessName}</h3>
        <h5 className='PostCategory d-flex'>
          <span className='d-flex gap-1'>
            <SvgIcon icon='mappin-fill' size="16" fill='#fff' />
            {item.BussinessLocation}
          </span>
          {item.BussinessTiming && 
            <span className='d-flex gap-1'>
              <SvgIcon icon='clock-fill' size="16" fill='#fff' />
              {item.BussinessTiming}
            </span>
          }
          <span className='d-flex gap-1'>
            <SvgIcon icon='notebook-fill' size="16" fill='#fff' />
            {item.Cost}/person
          </span>

          <span className='d-flex gap-1 ms-auto'>
            <SvgIcon icon='bookmark-fill' size="16" fill='#fff' />
          </span>
        </h5>
    </div>
  )
}

export default Posts
import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { setRedirect, setUserData } from '../../features/auth/authSlice'
import { Navigate, useNavigate } from 'react-router';

function Wrapper({ children, isMobile, setRedirect, Redirect, setUserData }) {
    const navigate = useNavigate();
    // console.log(Redirect);
    if(Redirect && Redirect !== window.location.pathname) {
        navigate(Redirect);
    } 
    if(Redirect === window.location.pathname) {
        setRedirect(null);
    }

    useEffect(() => {
        // console.log(localStorage.getItem('mobileNumber'))
        if(localStorage.getItem('mobileNumber')) {
            setUserData({'userNumber': localStorage.getItem('mobileNumber')});
        }
    }, [setUserData])
    
    return (
        <>
            {
                !isMobile ? 
                <>
                    <div className="section-heading d-flex justify-content-center align-items-center" style={{'height': '100vh', 'width': '100vw'}}>
                        <h2 className="title text-center">
                            Try it <br />on Your <br />Mobile Device
                        </h2>
                    </div>
                </>
                :
                <>
                    {children}
                </>
            }
        </>
    )
}

const mapStateToProps = ({ auth }) => {
    const { isMobile, Redirect } = auth;
    return {
        isMobile,
        Redirect
    }
  }
  
  const mapDispatchToProps = {
    setRedirect,
    setUserData
  }

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../AxiosConfig'
import config from '../../config'
import jwt_decode from 'jwt-decode'
import useGetSetLocationToLocal from '../../hooks/useGetSetLocationToLocal'



export const requestOtp = createAsyncThunk(
  'auth/requestOtp',
  async (params) => {
    const { mobileNumber } = params
    console.log(mobileNumber);
    const response = await axios.get(config.apiUrl + '/users/login?mobile=' + mobileNumber)
    const { data } = response
    return data
  }
)

export const validateOtp = createAsyncThunk(
  'auth/validateOtp',
  async (params) => {
    const { mobileNumber, otp } = params
    const response = await axios.get(config.apiUrl + '/users/login?mobile=' + mobileNumber + '&otp=' + otp)
    console.log(response)
    const { data } = response
    return data
  }
)

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (params) => {
    // const { UserMobileNumber, UserName } = params
    console.log(params);

    const response = await axios.post(config.apiUrl + '/users/register', params)
    console.log(response)
    const { data } = response
    return data
  }
)

export const updateUserName = createAsyncThunk(
  'auth/updateUserName',
  async (params) => {
    // const { UserMobileNumber, UserName } = params
    console.log(params);

    const response = await axios.post(config.apiUrl + '/users', params)
    console.log(response)
    // const { data } = response
    return { response, params }
  }
)

export const getCurrentUserLocation = createAsyncThunk(
  'auth/getCurrentUserLocation',
  async (params) => {
    const { latitude, longitude } = params
    console.log(params);

    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCaK88Wwejm0XznwP6BW4yVh8Inj9_l5nA`)
    const res = await response.json();
    const { results } = res
    console.log(res)
    return { results, latitude, longitude }
  }
)


export const getCurrentUserLocationAndSet = createAsyncThunk(
  'auth/getCurrentUserLocationAndSet',
  async (params) => {
    const { latitude, longitude } = params
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCaK88Wwejm0XznwP6BW4yVh8Inj9_l5nA`)
    const res = await response.json();
    const { results } = res
    return { results, latitude, longitude }
  }
)

export const getAllFilters = createAsyncThunk(
  'auth/getAllFilters',
  async (params) => {
    // console.log(params);

    const response = await axios.get(config.apiUrl + '/posts/getApiFilters')
    // console.log(response)
    const { data } = response.data
    return { data }
  }
)

export async function getAreas(params) {
    // console.log(params);
    const response = await axios.get(config.apiUrl + '/posts/getApiFilters/Area')
    // console.log(response)
    // const { data } = response.data
    return { response }
  }

const initialState = {
  authToken: null,
  isLogin: false,
  hideSendOtpButton: false,
  showVerifyOtpForm: false,
  showLoginForm: true,
  otpRequestSuccess: false,
  otpRequestError: false,
  otpValidateSuccess: false,
  otpValidateError: false,
  disableSubmitButton: false,
  userName: null,
  userNumber: null,
  userCategory: [],
  role: null,
  userInfo : null,
  userDeviceDetails: null,
  isMobile: true,
  defaultAvatars: [
    {
      'id': '1',
      'url': '/assets/img/sample/avatar/avatarG.png'
    },
    {
      'id': '2',
      'url': '/assets/img/sample/avatar/avatarG4.png'
    },
    {
      'id': '3',
      'url': '/assets/img/sample/avatar/avatarG2.png'
    },
    {
      'id': '4',
      'url': '/assets/img/sample/avatar/avatarG3.png'
    }
  ],
  defaultMainCities: [
    {
      'City': 'Delhi',
      'url': 'assets/img/sample/avatar/delhi.png'
    },
    {
      'City': 'Mumbai',
      'url': 'assets/img/sample/avatar/banglore.png'
    },
    {
      'City': 'Navi Mumbai',
      'url': 'assets/img/sample/avatar/mumbai.png'
    },
    {
      'City': 'Banglore',
      'url': 'assets/img/sample/avatar/banglore.png'
    },
    {
      'City': 'Chennai',
      'url': 'assets/img/sample/avatar/mumbai.png'
    },
    {
      'City': 'New Delhi',
      'url': 'assets/img/sample/avatar/delhi.png'
    },
    {
      'City': 'Pune',
      'url': 'assets/img/sample/avatar/mumbai.png'
    },
    {
      'City': 'Aurangabad',
      'url': 'assets/img/sample/avatar/banglore.png'
    }
  ],
  selectedCity: null,
  userLocation: null,
  userLocationDetails: null,
  selectedAvatar: null,
  activeFilter: '',
  CurrentFilters: null,
  SelectingFilters: {},
  FilterTagsList: null,
  PrevPage: '',
  Redirect: null,
  isCordova : true,
  method: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      // localStorage.clear()
      state.hideSendOtpButton = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.authToken = null
      state.isLogin = false
      state.role = null
      localStorage.clear();
    },
    backToLogin: (state) => {
      state.hideSendOtpButton = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.role = null
      state.isLogin = false

      state.authToken = null
      state.disableSubmitButton = false
      state.userName = null
      state.userNumber = null
      state.userCategory = []
      state.userInfo = null
      state.userDeviceDetails = null
      state.isMobile = true
      state.selectedCity = null
      state.userLocation = null
      state.userLocationDetails = null
      state.selectedAvatar = null
      state.activeFilter = ''
      state.CurrentFilters = null
      state.SelectingFilters = {}
      state.FilterTagsList = null
      state.PrevPage = ''
      state.Redirect = null
      state.isCordova = true
      localStorage.clear()
    },
    setUserData: (state, action) => {
      const { userName, userLocation, userNumber, userCategory, isLogin } = action.payload;
      console.log(userNumber);
      if (userName) {
        if(localStorage.getItem('UserName')) {
          state.userName = localStorage.getItem('UserName');
        } else  {
          state.userName = userName;
        }
      }
      if (userLocation) state.userLocation = userLocation;
      if (userNumber) state.userNumber = userNumber;
      if (userCategory) state.userCategory = userCategory;
      if (isLogin) state.isLogin = isLogin;
    },
    setToken: (state, action) => {
      state.isLogin = true;
      state.authToken = action.payload.authToken
      console.log(state.authToken)
      const tokenData = jwt_decode(action.payload.authToken);
      state.userInfo = tokenData.userInfo;
      state.role = tokenData.userInfo.RoleID;

        const { UserName, City, UserMobileNumber, UserImageLink, CuisinePreferences } =  tokenData.userInfo
        
        if (City) state.userLocation = City;
        if (UserMobileNumber) state.userNumber = UserMobileNumber;
        if (UserImageLink) state.selectedAvatar = UserImageLink;
        if (CuisinePreferences) state.CurrentFilters = CuisinePreferences

        if(localStorage.getItem('UserName')) {
          state.userName = localStorage.getItem('UserName');
        } else  {
          if (UserName) state.userName = UserName;
        }
    },
    setDeviceDetails: (state, action) => {
      // console.log(action.payload);
      if (action.payload.innerWidth <= 768) {
        state.isMobile = true;
      } else {
        state.isMobile = false;
      }
      state.userDeviceDetails = action.payload;
    },
    setSelectedAvatar: (state, action) => {
      state.selectedAvatar = action.payload
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload
    },
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload
    },
    setUpdatedFilters: (state, action) => {
      state.SelectingFilters = action.payload
    },
    setPrevPage: (state, action) => {
      state.PrevPage = action.payload
    },
    setRedirect: (state, action) => {
      state.Redirect = action.payload
    },
    setDisableButton: (state, action) => {
      state.disableSubmitButton = action.payload
    },
    setLocation: (state, action) => {
      const { userLocation, userLocationDetails } = action.payload;
      state.userLocation = userLocation
      state.userLocationDetails = userLocationDetails
    },
    setUserLocationDetails: (state, action) => {
      state.userLocationDetails = action.payload
    },
    setCurrentPrefrences: (state, action) => {
      state.CurrentFilters = action.payload;
    },
    setPostMethod: (state, action) => {
      state.method = action.payload
      if(action.payload === 'static') {
        state.userLocationDetails = null
      }
    }
  },
  extraReducers: {
    [requestOtp.fulfilled]: (state, action) => {
      const { message, data } = action.payload
      if (message === 'Success') {
        if (data === 'OTP Sent') {
          state.hideSendOtpButton = true
          state.showVerifyOtpForm = true
          state.otpRequestSuccess = true
        } else {
          state.otpRequestError = true
        }
      } else {
        state.hideSendOtpButton = false
        state.showVerifyOtpForm = false
      }
    },
    // [requestOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [requestOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // },
    [validateOtp.fulfilled]: (state, action) => {
      console.log(action)
      const { data } = action.payload
      if (data === 'Incorrect OTP') {
        state.otpValidateError = true
        state.authToken = null
      } else if (data === 'User Not Exist') {
        state.Redirect = '/RegisterYourName';
        state.userNumber = action.meta.arg.mobileNumber;
        localStorage.setItem('mobileNumber', action.meta.arg.mobileNumber);
      } else {
        state.otpValidateError = false
        localStorage.setItem('token', data)
        state.authToken = data
        state.otpValidateSuccess = true
        state.isLogin = true

        const tokenData = jwt_decode(data);
        state.userInfo = tokenData.userInfo;
        const { UserName, City, UserMobileNumber, UserImageLink, CuisinePreferences } =  tokenData.userInfo
        if(localStorage.getItem('UserName')) {
          state.userName = localStorage.getItem('UserName');
        } else  {
          if (UserName) state.userName = UserName;
        }
        if (City) state.userLocation = City;
        if (UserMobileNumber) state.userNumber = UserMobileNumber;
        if (UserImageLink) state.selectedAvatar = UserImageLink;
        if (CuisinePreferences) state.CurrentFilters = CuisinePreferences
      }
    },
    [registerUser.fulfilled]: (state, action) => {
      console.log(action)
      const { data, status } = action.payload
      if (data.token) {
        state.otpValidateError = false
        localStorage.setItem('token', data.token)
        state.authToken = data.token
        state.otpValidateSuccess = true
        state.isLogin = true

        const tokenData = jwt_decode(data.token);
        state.userInfo = tokenData.userInfo;
        const { UserName, City, UserMobileNumber, UserImageLink, CuisinePreferences } =  tokenData.userInfo
        if(localStorage.getItem('UserName')) {
          state.userName = localStorage.getItem('UserName');
        } else  {
          if (UserName) state.userName = UserName;
        }
        if (City) state.userLocation = City;
        if (UserMobileNumber) state.userNumber = UserMobileNumber;
        if (UserImageLink) state.selectedAvatar = UserImageLink;
        if (CuisinePreferences) state.CurrentFilters = CuisinePreferences
      } else {
        state.otpValidateError = true
        state.authToken = null
      }
    },

    [getCurrentUserLocation.fulfilled]: (state, action) => {
      const { results, longitude, latitude } = action.payload
      const AllData = results;
      console.log(action.payload)
      if(AllData[0]) {
        let FilterData = AllData[0].address_components.filter((item) => item.types.includes("sublocality"));
        state.userLocation = FilterData[FilterData.length - 1].long_name;
        state.userLocationDetails = {
          lat : latitude,
          long : longitude
        }
        const {setPlaceToLocal, setPlaceLatLong} = useGetSetLocationToLocal();
        setPlaceToLocal(FilterData[FilterData.length - 1].long_name);
        setPlaceLatLong({
          lat : latitude,
          long : longitude
        })
      } else {
        state.userLocation = '';
        state.userLocationDetails = null;
      }
      // console.log(AllData[0])
    },
    [getCurrentUserLocationAndSet.fulfilled]: (state, action) => {
      const { results, longitude, latitude } = action.payload
      const AllData = results;
      if(AllData[0]) {
        let FilterData = AllData[0].address_components.filter((item) => item.types.includes("sublocality"));
        state.userLocation = FilterData[FilterData.length - 1].long_name;
        state.userLocationDetails = {
          lat : latitude,
          long : longitude
        }
        const {setPlaceToLocal, setPlaceLatLong} = useGetSetLocationToLocal();
        setPlaceToLocal(FilterData[FilterData.length - 1].long_name);
        setPlaceLatLong({
          lat : latitude,
          long : longitude
        })
      } else {
        state.userLocation = '';
        state.userLocationDetails = null;
      }
      // console.log(AllData[0])
    },
    [getAllFilters.fulfilled] : (state, action) => {
      const { data } = action.payload;
      if(data) {
        state.FilterTagsList = data;
        // console.log(Object.keys(data)[0]);
        state.activeFilter = Object.keys(data)[0];
      }
    },
    [updateUserName.fulfilled]: (state, action) => {
      const { response, params } = action.payload
      console.log(response, params);
      if(response.data.message === 'Success') {
        state.userName = params.UserName;
        localStorage.setItem('UserName', params.UserName);
      }
    },
    // [validateOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [validateOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // }
  }
})

export const { logout, backToLogin, setToken, setUserData, setDeviceDetails, setSelectedAvatar, setSelectedCity, setActiveFilter, setUpdatedFilters, setRedirect, setDisableButton, setLocation, setCurrentPrefrences, setUserLocationDetails, setPostMethod } = authSlice.actions;

export default authSlice.reducer

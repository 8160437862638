import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';

function BackButton({ LinkToGo, children, variant = 'link', className = '' }) {
    
    const navigate = useNavigate();
    function GoToFilter() {
      console.log(LinkToGo);
      if(LinkToGo) {
        navigate(LinkToGo);
      }
      }

  return (
    <Button variant={variant} className={className} onClick={GoToFilter}>
        {children}
    </Button>
  )
}

export default BackButton
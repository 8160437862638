import React, { useState } from 'react'
import Posts from './Components/Posts';
import PageBottomNavigation from './Components/PageBottomNavigation';
import { connect } from "react-redux";
import { useNavigate } from 'react-router';
import { setUserData, backToLogin } from '../../features/auth/authSlice';
import { resetPosts } from '../../features/postApi/postSlice'
import BackButton from './Components/BackButton';
import SvgIcon from '../../svg/SvgIcon';
import { Dropdown, Form } from 'react-bootstrap';
import { useEffect } from 'react';
import axios from '../../AxiosConfig'

function ProfileSection({
    userName,
    userLocation,
    userCategory,
    isLogin,
    userNumber,
    setUserData,
    selectedAvatar,
    defaultAvatars,
    backToLogin,
    resetPosts,
    userLocationDetails
}) {
    const navigate = useNavigate();
    console.log(userLocationDetails);
    const [BookmarkPost, setBookmarkPost] = useState(null);

    useEffect(() => {
        axios.get(`/posts/postBookmark`).then(res => {
            if (res.data.message === 'Success') {
                if(res.data.data.length > 0) {
                    setBookmarkPost(res.data.data);
                }
                // setLoadingComments(false);
            }
          })
    }, [])

    function toLogout() {
        backToLogin();
        resetPosts();
        navigate('/login');
    }
    return (
        <div className='height100vh'>
            <div className='ProfilePage padding-2 pb-0'>
                <div className='profileTitleSection'>
                    <div className='d-flex align-items-center'>
                        <BackButton className="ps-0" LinkToGo='/FoodJointsNearby'>
                            <SvgIcon icon='arrowLeft' size='24' />
                        </BackButton>
                        <h5 className='sectionName mb-0 p-0'>Profile</h5>
                    </div>

                    <Dropdown>
                        <Dropdown.Toggle variant="link" className='px-0' id="dropdown-basic">
                            <SvgIcon icon="dots" size="24" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate('/account-deletion')}>Delete Account</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Check // prettier-ignore
                        // disabled
                        className='SwitchComponent'
                        type="switch"
                        id="disabled-custom-switch"
                    /> */}
                    {/* <h1 className='mb-0 d-flex justify-content-center' onClick={() => navigate('/getLocation')}><ion-icon name="create-outline"></ion-icon></h1> */}
                </div>

                <div className='ProfileCardSection'>
                    <div className='profilePhoto'>
                        <img src={selectedAvatar ? selectedAvatar : defaultAvatars[1].url} placeholder='profile photo' />
                    </div>
                    <div className='profileInformations'>
                        <h4 className='ProfileName'>{userName}</h4>
                        {/* <p className='ProfileLocation'>City: {userLocation}</p> */}
                        {/* <p className='ProfileCousine'>Cousine: {
                            userCategory.map((item) => {
                                return <> {item},</>
                            })}
                        </p> */}
                        <p className='ProfilePhone'>Phone No: {userNumber}</p>
                    </div>
                    <div className='d-flex flex-column gap-1 align-items-center profileBtnDiv  ms-auto'>
                        <BackButton LinkToGo='/RegisterYourName' className='p-0'>
                            <SvgIcon icon="notePencil-fill" size='20'/>
                        </BackButton>
                        <button className="btn btn-link mx-0 px-0" onClick={toLogout}>
                            <SvgIcon icon='logout-fill' size='20'/>
                        </button>
                    </div>
                    
                    {/* <h1 className='mb-0 d-flex justify-content-center' onClick={() => navigate('/getLocation')}><ion-icon name="create-outline"></ion-icon></h1> */}
                </div>

                {
                    isLogin ?
                    <>
                        {/* <div className='d-flex gap-1 align-items-center'>
                            <h1 className='mb-0 d-flex justify-content-center'><ion-icon name="bookmark"></ion-icon></h1>
                            <h5 className='sectionName mb-0'>Your Saved Posts</h5>
                        </div> */}
                    </>
                    :
                    <>
                        <br />
                        <div>
                            <button className='primaryThemeButton' onClick={() => navigate('/login')}>
                                Login to see more
                            </button>
                        </div>
                    </>
                }
                {
                    isLogin ?
                        <>
                            <div className='SavedPostsContainer'>
                                {
                                    BookmarkPost && BookmarkPost.map((post) => {
                                        return <Posts item={post} />
                                    })
                                }
                            </div>
                        </>
                        :
                        <></>
                }
                <div className='discoverMore'>
                    <button class="primaryThemeButton" onClick={() => navigate('/FoodJointsNearby')}>
                        {/* <SvgIcon icon='caretCircleLeft-fill' size="20" fill='#ffffff' /> */}
                        Continue
                    </button>
                </div>
            </div>
            {/* <PageBottomNavigation page='ProfilePage' /> */}
        </div>
    )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {

    } = appToast

    const {
        userName,
        userLocation,
        userCategory,
        isLogin,
        userNumber,
        selectedAvatar,
        defaultAvatars,
        userLocationDetails
    } = auth

    return {
        userName,
        userLocation,
        userCategory,
        isLogin,
        userNumber,
        selectedAvatar,
        defaultAvatars,
        userLocationDetails
    }
}

const mapDispatchToProps = {
    setUserData,
    backToLogin,
    resetPosts
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection)
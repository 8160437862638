import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import SvgIcon from '../../svg/SvgIcon'
import { useNavigate } from 'react-router'
import config from '../../config'
import axios from '../../AxiosConfig';
import { backToLogin } from '../../features/auth/authSlice'

function DeleteModal({ ShowSuccess, show, handleClose, DeleteCall, disableButton, Error }) {
    return (
        <>
            <Modal show={show} className='themeModal' backdrop="static" centered onHide={handleClose}>

                <Modal.Body className='border-0 py-0'>
                    {
                        ShowSuccess ?
                        <>
                            <div className='ReportSuccess'>
                                <SvgIcon icon="checkCircle" className='LikedPost' size="80" />
                            </div>
                        </>
                        :
                        <div className='ReportSuccess'>
                            <SvgIcon icon="warning" className='LikedPost' size="50" />
                            <p className='text-center fw-900 mb-0'>Are you sure you want to delete your account? This action will permanently remove your personal data and cannot be undone.</p>
                            {Error && <span className='text-danger text-center'>{Error}</span>}
                        </div>
                    }
                </Modal.Body>
                {
                    !ShowSuccess &&
                    <Modal.Footer className='border-0'>
                        <div className='d-flex gap-1 w-100'>
                            <button className="primaryThemeButtonTransparent" onClick={handleClose}>
                                Close
                            </button>
                            <button className='primaryThemeButton' disabled={disableButton} onClick={DeleteCall}>
                                Delete
                            </button>
                        </div>
                    </Modal.Footer>
                }
            </Modal>
        </>
    )
}

const DeleteUser = ({
    userNumber,
    isLogin,
    backToLogin
}) => {
    console.log(userNumber, isLogin);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const [ShowSuccess, setShowSuccess] = useState(false);

    const [disableButton, setDisableButton] = useState(false);

    const [Error, setError] = useState(null);

    const [OTPvalue, setOTPvalue] = useState('');

    const changeOTP = (otp) => {
        if(otp.length <= 6) {
            setOTPvalue(otp);
        }
    }

    const handleClose = () => {
        setShow(false);
        setError(null);
    };

    function toLogout() {
        backToLogin();
        navigate('/login');
    }

    const DeleteCall = async () => {
        setDisableButton(true);
        const response = await axios.get(config.apiUrl + '/users/deleteUser');
        console.log(response);
        if(response.data.message === 'Success') {
            setShowSuccess(true);
            setTimeout(() => {
                toLogout();
                handleClose();
            }, 3000);
        } else {
            setDisableButton(false);
            setError('Something went wrong, try again later');
        }
    }

    const [step, setStep] = useState('Step1');

    const onStep1Click = async () => {
        setDisableButton(true);
        const response = await axios.get(config.apiUrl + '/users/login?mobile=' + userNumber);
        if(response.data.data === 'OTP Sent'){
            setDisableButton(false);
            setStep('Step2');
        }
    }

    const onStep2Click = async () => {

        if(OTPvalue.toString().trim().length !== 6){
            setError('Please enter 6 digit OTP');
            return
        }
        setDisableButton(true);
        setError(null);
        const response = await axios.get(config.apiUrl + '/users/login?mobile=' + userNumber + '&otp=' + OTPvalue);
        if(response.data.data === 'Incorrect OTP'){
            setError('Enter valid OTP');
        } else {
            setStep('Step3');
        }
        setDisableButton(false);
    }

    return (
        <div className='TopBgImage padding-2 height100vh'>
            <div className='ChooseMainCityPage HungryPage deletePage'>
                <button onClick={() => navigate('/profile')} type='button' style={{'width': 'fit-content'}} className='primaryThemeButton btn-icon-only position-absolute t-4 s-3 d-flex'>
                    <SvgIcon icon="arrowLeft" size="20" fill="#fff" />
                </button>
                <div className='TopSection'>
                    <h3>foodaspas</h3>
                </div>
                <div className='MiddleSection'>
                    <div className='ImageSection'>
                        <img src='assets/img/delete-account.svg' alt='delete?' />
                        <div>
                            <h4 className='text-center'>Request Account Deletion</h4>
                            <p className='text-center'>Follow the steps to delete your account and remove your personal data.</p>
                        </div>

                    </div>

                    <div className='DeletionSteps'>
                        {
                            step === 'Step1' &&
                            <div className='Step'>
                                <p className='text-center'>Click the button below to receive an OTP on your registered mobile number.</p>
                            </div>
                        }
                        {
                            step === 'Step2' &&
                            <div className='Step'>
                                <p className='text-center'>Enter the OTP you received to verify your request.</p>
                                
                            </div>
                        }
                        {
                            step === 'Step3' &&
                            <div className='Step'>
                                <p className='text-center'>Once verified, click the delete button to permanently remove your personal data from our system.</p>
                                
                            </div>
                        }
                    </div>
                </div>

                <div className='BottomSection'>
                    {
                            step === 'Step1' && <>
                                <button className='primaryThemeButton' disabled={disableButton} onClick={() => onStep1Click()}>
                                    Send OTP
                                </button>
                                {/* <button className='primaryThemeButtonOutline' onClick={() => navigate('/profile')}>
                                    Cancel
                                </button> */}
                            </>
                    }

                    {
                        step === 'Step2' && <>
                            <div className={`OtpValidation ${Error && 'invalid'} pt-3`}>
                                <input type="number" value={OTPvalue} onChange={(e) => changeOTP(e.target.value)} autoFocus className="form-control text-center" placeholder='Enter OTP' name='OTP' aria-label="" />
                                {Error && <div class="invalid-feedback text-center">{Error}</div>}
                            </div>
                            <button className='primaryThemeButton' disabled={disableButton} onClick={() => onStep2Click()}>
                                Verify OTP
                            </button>
                        </>
                    }

                    {
                            step === 'Step3' && <>
                                <button className='primaryThemeButton' onClick={() => setShow(true)}>
                                    Delete Account
                                </button>
                            </>
                    }
                </div>
            </div>
            <DeleteModal
            handleClose={handleClose}
            show={show}
            DeleteCall={DeleteCall}
            ShowSuccess={ShowSuccess}
            disableButton={disableButton}
            Error={Error} />
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const {
        authToken,
        userNumber,
        isLogin
    } = auth
    
    return {
        authToken,
        userNumber,
        isLogin
    }
  }

const mapDispatchToProps = {
    backToLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser)
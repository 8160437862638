import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { connect } from "react-redux";
import axios from '../../AxiosConfig'
import { setActiveFilter, setUpdatedFilters, getAllFilters, setCurrentPrefrences } from '../../features/auth/authSlice';
import { setFeeds } from '../../features/postApi/postSlice'
import SvgIcon from '../../svg/SvgIcon'
import { Badge, Button } from 'react-bootstrap'
import BackButton from './Components/BackButton';

function Filters({ filters, activeFilter, setActiveFilter, setUpdatedFilters, FilterTagsList, SelectingFilters, getAllFilters, setFeeds, setCurrentPrefrences, userLocationDetails, CurrentFilters, userLocation, selectedCity }) {
    // console.log(activeFilter);
    const navigat = useNavigate();
    const [ShowLoading, setShowLoading] = useState(false);
    const [TotalFoodJoints, setTotalFoodJoints] = useState(0);

    const [searchText, setSearchText] = useState('');

    const [TagsLists, setTagsLists] = useState({});
    const [SelectingFiltersLists, setSelectingFiltersLists] = useState({});

    function AddToFilter(ID) {
        const TempSelectingFilters = JSON.parse(JSON.stringify(SelectingFiltersLists));
        if(!TempSelectingFilters[activeFilter]) TempSelectingFilters[activeFilter] = [];
        TempSelectingFilters[activeFilter].push(ID);
        // console.log(TempSelectingFilters,activeFilter, ID);
        setSelectingFiltersLists(TempSelectingFilters)

        const TempTagsLists = JSON.parse(JSON.stringify(TagsLists));
        TempTagsLists[activeFilter]  =  TempTagsLists[activeFilter].filter((tag) => tag !== ID);
        setTagsLists(TempTagsLists);
    }

    function RemoveFromFilter(ID) {
        // const TempSelectingFilters = JSON.parse(JSON.stringify(SelectingFiltersLists));
        // TempSelectingFilters[activeFilter].Filters = TempSelectingFilters[activeFilter].Filters.filter((itm) => itm !== ID);
        // setSelectingFiltersLists(TempSelectingFilters);

        const TempSelectingFilters = JSON.parse(JSON.stringify(SelectingFiltersLists));

        TempSelectingFilters[activeFilter]  =  TempSelectingFilters[activeFilter].filter((tag) => tag !== ID);

        setSelectingFiltersLists(TempSelectingFilters)

        const TempTagsLists = JSON.parse(JSON.stringify(TagsLists));

        if(!TempTagsLists[activeFilter]) TempTagsLists[activeFilter] = [];
        TempTagsLists[activeFilter].push(ID);
        setTagsLists(TempTagsLists);
        
    }

    function ToClearAll() {
        setSelectingFiltersLists({});
        setTagsLists(FilterTagsList);
        setTotalFoodJoints(0);
    }

    useEffect(() => {
        setTagsLists(FilterTagsList);
        setSelectingFiltersLists(SelectingFilters);
    }, [FilterTagsList, SelectingFilters])

    
    useEffect(() => {
        if(FilterTagsList === null) {
            getAllFilters();
        }
    }, [FilterTagsList])

    function checkObject(obj) {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (!Array.isArray(obj[key]) || obj[key].length === 0) {
              return false;
            }
          }
        }
        return true;
    }

    useEffect(() => {
      if(Object.keys(SelectingFiltersLists).length > 0 && checkObject(SelectingFiltersLists)) {
        setShowLoading(true)
        let urlToUse = `/Posts`;
        const SendData = {
            pageSize : 10,
            pageIndex : 0,
            lat : (userLocationDetails && userLocationDetails.lat) ? userLocationDetails.lat : null,
            long : (userLocationDetails && userLocationDetails.long) ? userLocationDetails.long : null,
            place : selectedCity
        }
        if(SelectingFiltersLists && Object.keys(SelectingFiltersLists).length > 0) {
            SendData.CurrentFilters = SelectingFiltersLists
        }
        axios.post(urlToUse, SendData)
            .then(res => {
                console.log(res);
                setShowLoading(false)
                if(res.data.data) {
                    setTotalFoodJoints(res.data.data.TotalCount);
                } else {
                    setTotalFoodJoints([]);
                }
            })
            .catch(err => {
                console.log(err);
                setTotalFoodJoints([]);
            });
      }

      if(!checkObject(SelectingFiltersLists)) {
        setTotalFoodJoints(0);
      }
    //    else {
    //     ToClearAll();
    //   }
    }, [SelectingFiltersLists])

    function ApplyFilter() {
        // setFeeds(TotalFoodJoints);
        // setStopCall(true);
        if(Object.keys(SelectingFiltersLists).length > 0) {
            const filteredObj = Object.fromEntries(
                Object.entries(SelectingFiltersLists).filter(([key, value]) => Array.isArray(value) && value.length > 0)
            );

            console.log(filteredObj);
            setCurrentPrefrences(filteredObj);
        } else {
            setCurrentPrefrences(null);
        }
        navigat('/FoodJointsNearby');
    }

    useEffect(() => {
        if(FilterTagsList && CurrentFilters) {
            const newObj = {};
                for (const key in FilterTagsList) {
                    if (CurrentFilters.hasOwnProperty(key)) {
                    newObj[key] = FilterTagsList[key].filter(value => !CurrentFilters[key].includes(value));
                    } else {
                    newObj[key] = FilterTagsList[key].slice();
                    }
                }
                console.log({ CurrentFilters, FilterTagsList, newObj })
                setTagsLists(newObj);
                setSelectingFiltersLists(CurrentFilters);
        }

    }, [CurrentFilters, FilterTagsList])
    

    
  return (
    <div className='height100vh FilterPage'>
        <div className='FilterHeaderSection'>
            <div>
                <BackButton variant='link' LinkToGo='/FoodJointsNearby'>
                    <SvgIcon icon='arrowLeft' size="20" />
                </BackButton>
                {/* <Button variant='link'></Button> */}
                <h5>Filters</h5>
            </div>
            <Button variant='link' onClick={ToClearAll}>Clear All</Button>
        </div>

        <div className='FiltersMiddleSection d-flex mx-0'>
            <div className='leftSide px-0'>
                <div className='MainFilters'>
                    {
                        TagsLists && Object.keys(TagsLists).map((FilterType, i) => {
                            // console.log(FilterType);
                            
                            return (
                                <button key={i} className={`${activeFilter === FilterType ? 'activeFilter FilterButton' : 'FilterButton' }`} onClick={() => setActiveFilter(FilterType)}>
                                    {/* {TagsLists[FilterType].Label} */}
                                    {FilterType}
                                </button>
                            )
                        })
                    }
                </div>
            </div>
            <div className='rightSide p-2'>
                <div className='SearchFilters'>
                    <input type='text' value={searchText} onChange={(e) => setSearchText(e.target.value)} className='searchText' placeholder='Search Your Favourite' />
                </div>
                <div className='SelectionTags'>

                    <div className='FilterSelectedTags'>
                        {/* {
                            TagsLists[activeFilter]?.Filters.map((itm, i) => {
                                return (
                                    <>
                                        {
                                            SelectingFiltersLists[activeFilter]?.Filters?.includes(itm.ID) &&
                                            <Button variant='outline-muted' size='sm' key={i} onClick={() => RemoveFromFilter(itm.ID)}>
                                                {itm.label}
                                                <SvgIcon className='ms-1' icon='xSquare' fill='#C0C0C0' size="16" />
                                            </Button>
                                        }
                                    </>
                                )
                            })
                        } */}

                        {
                            SelectingFiltersLists && SelectingFiltersLists[activeFilter]?.map((itm, i) => {
                                return (
                                    <>
                                        <Button variant='outline-muted' size='sm' key={i} onClick={() => RemoveFromFilter(itm)}>
                                            {itm}
                                            <SvgIcon className='ms-1' icon='xSquare' fill='#C0C0C0' size="16" />
                                        </Button>
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className='FilterCanBeSelectedTags'>
                        {
                            TagsLists && TagsLists[activeFilter]?.map((itm, i) => {
                                return (
                                    <>
                                        {
                                            (itm.toString().trim().includes(searchText.toString().trim()) ||
                                            searchText.toString().trim().length === 0) &&
                                            <Button variant='outline-muted' size='sm' key={i} onClick={() => AddToFilter(itm)}>{itm}</Button>
                                        }
                                        {/* {
                                            (!SelectingFiltersLists[activeFilter]?.Filters?.includes(itm.ID) || !SelectingFiltersLists[activeFilter].Filters) &&
                                            <Button variant='outline-muted' size='sm' key={i} onClick={() => AddToFilter(itm.ID)}>{itm.label}</Button>
                                        } */}
                                    </>
                                )
                            })
                        }
                    </div> 
                </div>
            </div>
        </div>

        <div className='FilterBottomSection'>
            <div className='TotalFoodJoints'>
                    <h4 className='mb-0'>
                    {/* <span className='loader d-block'></span> */}
                        {
                            ShowLoading ?
                            <div className='spinnerLoader' style={{'width': '25px'}}></div>
                            :
                            <>{TotalFoodJoints}</>
                        }
                    </h4>
                <span>Foodjoints Found</span>
            </div>

            <button className='primaryThemeButton' onClick={ApplyFilter}>Apply</button>
        </div>
    </div>
  )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {} = appToast
  
    const {
      userLocation,
      selectedCity,
      activeFilter,
      FilterTagsList,
      SelectingFilters,
      userLocationDetails,
      CurrentFilters
    } = auth
  
    return {
      userLocation,
      selectedCity,
      activeFilter,
      FilterTagsList,
      SelectingFilters,
      userLocationDetails,
      CurrentFilters
    }
  }
  
  const mapDispatchToProps = {
    setActiveFilter, setUpdatedFilters, getAllFilters, setFeeds, setCurrentPrefrences
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Filters)
import React, { useEffect } from 'react'
import '../Pages/AllPostViewCard.css'

function AllPostViewCard({
    handleScroll,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    setIsDoneCard,
    currentActive,
    number,
    item
}) {
  useEffect(() => {
    if(currentActive === number) {
      setIsDoneCard(number);
    }
  }, [currentActive])
  
  return (
    <div
    className='CompletedCard FoodDetailCard'
    // onScroll={handleScroll}
    // onTouchStart={handleTouchStart}
    // onTouchMove={handleTouchMove}
    // onTouchEnd={(e) => handleTouchEnd(e, 'DoneCard')}
    >
        <div className='Top'>
            <div className='checkContainer'>
              <img src='/assets/img/icon/SealCheck.svg' alt='Check' className='checkMarkBg' />
              <img src='/assets/img/icon/CheckFat.svg' className='checkMark' alt='Check' />
            </div>
            <h4>{item.Key1 ? item.Key1 : 'Great!'}</h4>
            <p>{item.Key2 ? item.Key2 : 'You have watched all the vlogs for food joints in your selected area!'}</p>
        </div>
        <div className='Bottom'>
            <img src='/assets/img/icon/upscroll.svg' alt='Check' />
            <p>Swipe up to explore some more food joints near you!</p>
        </div>
    </div>
  )
}

export default AllPostViewCard
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../../features/auth/authSlice'
// import Logo from '../../images/logo.svg'

const Header = ({
  logout
}) => {
  let navigate = useNavigate()

  const handleUserProfile = () => {
    navigate('/userProfile')
  }
  return (
    <div>
      {/* <AppToast
        message={appToastMessage}
        show={appToastVisible}
        background={appToastBackground}
        icon={appToastIcon}
        position={appToastPosition}
      /> */}
      <div className="appHeader">
        <div className="row w-100 d-flex align-items-center">
          <div className="w-25 d-flex justify-content-start">
            <a href="#!" className="headerButton">
              {/* <img src={Logo} className="img-fluid" alt="Brand" width="69x69" /> */}
            </a>
          </div>
          <div className="w-50 dropdown text-center">
            <div className="form-group boxed">
              <div className="input-wrapper d-flex  justify-content-center">
                <div class="d-none d-md-flex">
                  <div class="dropdown">
                    <Link to={'/summary'} class="me-1">
                      <div class="chip chip-media chip-outline">
                          <i class="chip-icon bg-primary">
                              <ion-icon name="storefront"></ion-icon>
                          </i>
                          <span class="chip-label">Summary</span>
                      </div>
                    </Link>
                    {/* <Link to={'/summary'} class="btn btn-primary rounded shadowed me-1 btn-sm text-nowrap">
                      <ion-icon name="storefront-outline" class="fs-6"></ion-icon>
                      Summary
                    </Link> */}
                  </div>
                  <div class="dropdown">
                    <Link to={'/items'} class="me-1">
                      <div class="chip chip-media chip-outline">
                          <i class="chip-icon bg-primary">
                              <ion-icon name="cube"></ion-icon>
                          </i>
                          <span class="chip-label">Items</span>
                      </div>
                    </Link>
                    {/* <Link to={'/items'} class="btn btn-primary rounded shadowed me-1 btn-sm text-nowrap">
                      <ion-icon name="cube-outline" class="fs-6"></ion-icon>
                      Items
                    </Link> */}
                  </div>
                  <div class="dropdown">
                    <Link to={'/newOrder'} class="me-1">
                      <div class="chip chip-media chip-outline">
                          <i class="chip-icon bg-primary">
                              <ion-icon name="add"></ion-icon>
                          </i>
                          <span class="chip-label text-nowrap">New Order</span>
                      </div>
                    </Link>
                    {/* <Link to={'/newOrder'} class="btn btn-primary rounded shadowed me-1 btn-sm text-nowrap">
                      <ion-icon name="add-outline" class="fs-6"></ion-icon>
                      New Order
                    </Link> */}
                  </div>
                  <div class="dropdown">
                    <Link to={'/orders?tab=sales'} class="me-1">
                      <div class="chip chip-media chip-outline">
                          <i class="chip-icon bg-primary">
                              <ion-icon name="cart"></ion-icon>
                          </i>
                          <span class="chip-label">Orders</span>
                      </div>
                    </Link>
                    {/* <Link to={'/orders'} class="btn btn-primary rounded shadowed me-1 btn-sm text-nowrap">
                      <ion-icon name="cart-outline" class="fs-6"></ion-icon>
                      Orders
                    </Link> */}
                  </div>
                  <div class="dropdown">
                    <Link to={'/transactions'} class="me-1">
                      <div class="chip chip-media chip-outline">
                          <i class="chip-icon bg-primary">
                              <ion-icon name="swap-vertical"></ion-icon>
                          </i>
                          <span class="chip-label">Transactions</span>
                      </div>
                    </Link>
                    {/* <Link to={'/transactions'} class="btn btn-primary rounded shadowed me-1 btn-sm text-nowrap">
                      <ion-icon name="swap-vertical-outline" class="fs-6"></ion-icon>
                      Transactions
                    </Link> */}
                  </div>
                </div>
                <div class="d-block d-sm-none">
                    <h4 className="mb-0 d-flex justify-content-center align-items-center">
                      <span className='d-block d-lg-none my-auto pt-1 ms-1'></span>
                    </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="w-25 dropdown d-flex justify-content-end text-secondary fs-4 align-items-center">
              <Link to="/summary">
                <h6 class="mb-0 fs-10 me-2 d-flex align-items-center justify-content-center text-center flex-column" style={{height: '24px'}}>
                    <small class="text-nowrap">Today's Sale</small>
                    <span class="fs-6 fw-bolder">₹9000</span>
                </h6>
              </Link>
              <span className="dropdown">
                <span
                  className="headerButton d-flex"
                  data-bs-toggle="dropdown"
                  style={{ cursor: 'pointer' }}
                >
                  {/* <ion-icon name="ellipsis-vertical" className="fs-4"></ion-icon> */}
                  <div class="rounded-circle webweb-border-secondary d-flex align-items-center justify-content-center" style={{height: '32px', width: '32px'}} onClick={handleUserProfile}>
                    <ion-icon name="person-outline" class="fs-5"></ion-icon>
                  </div>
                </span>
                {/* <div className="webweb-dropdown-menu dropdown-menu dropdown-menu-end">
                  <Link className="dropdown-item" to="/appInfo">
                    <ion-icon name="headset-outline"></ion-icon> Support
                  </Link>
                  <a className="dropdown-item" href="#!" onClick={handleLogout}>
                    <ion-icon name="log-out-outline"></ion-icon> Logout
                  </a>
                </div> */}
              </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
  logout
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)

import React from 'react'
import { useNavigate } from 'react-router'

function UploadProfileImage() {
    const navigate = useNavigate();
  return (
    <div className='TopBgImage padding-2 height100vh'>
            <div className='RegisterYourNumberPage UploadProfileImage'>
                <div className='TopSection'>
                    <h3>foodaspas</h3>
                </div>
                <div className='MiddleSection'>
                        <img src='assets/img/sample/avatar/avatar1.jpg' className='defaultImage ProfileImage' />
                        <input type="file" id='UploadFile' className="form-control d-none" />
                        <label htmlFor='UploadFile'>Upload</label>
                </div>

                <div className='BottomSection'>
                    <button className='primaryThemeButton' onClick={() => navigate('/Profile')}>
                        Continue
                    </button>
                    <button className='primaryThemeButtonTransparent' onClick={() =>  navigate('/RegisterYourNumber')}>
                        Go Back
                    </button>
                </div>
            </div>
        </div>
  )
}

export default UploadProfileImage
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import config from '../../config'

export const getReportMessages = createAsyncThunk(
  'postApi/getReportMessages',
  async (params) => {
    // console.log(params);
    console.log('data')

    const response = await fetch(config.apiUrl + `/reports/getReportTypeList`)
    const res = await response.json();
    const { data } = res
    return { data }
  }
)

const initialState = {
    Feeds: [],
    StopCall: false,
    ReportMessages : null
}

export const postSlice = createSlice({
  name: 'postApi',
  initialState,
  reducers: {
    setFeeds: (state, action) => {
        state.Feeds = action.payload
    },
    setStopCall: (state, action) => {
      state.StopCall = action.payload
    },
    resetPosts: (state, action) => {
      state.Feeds = [];
      state.StopCall = false;
      state.ReportMessages = null
    }
  },
  extraReducers: {
    [getReportMessages.fulfilled]: (state, action) => {
      const { data } = action.payload
      if(data) {
        state.ReportMessages = data;
      }
    },
  }
})

export const { setFeeds, setStopCall, resetPosts } = postSlice.actions;

export function getYoutubeThumbnailUrl(url) {
  // Extract video ID from the URL
  const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^\s/]+)/);
  
  if (videoIdMatch && videoIdMatch[1]) {
      const videoId = videoIdMatch[1];
      // Construct thumbnail URL
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      return thumbnailUrl;
  } else {
      return "Invalid YouTube URL";
  }
}

export default postSlice.reducer

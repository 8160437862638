import React, { useState, useEffect, useLayoutEffect } from 'react'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import { Modal, Button } from 'react-bootstrap'
import { getReportMessages } from '../../../features/postApi/postSlice'
import SvgIcon from '../../../svg/SvgIcon'

function ReportModal({ selectedAvatar, defaultAvatars,setReportData, ReportData, ReportMessages, getReportMessages }) {


    const [show, setShow] = useState(false);

    const [ShowSuccess, setShowSuccess] = useState(false);

    const [ReportType, setReportType] = useState(null);
    const [ReportDescription, setReportDescription] = useState("");


    const [errorVaidation, setErrorValidation] = useState(false);
    const [errors, setErrors] = useState({
        'ReportDescription' : null
    });

    const handleClose = () => {
        setShow(false);
        setReportData(null);
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
      if(ReportMessages !== null && ReportData !== null) {
        setReportType(ReportMessages[ReportData.type][0])
      }
    }, [ReportMessages, ReportData])

    function changeDescription(desc) {
        setReportDescription(desc);

        if(errorVaidation && desc.length > 0) {
            setErrors((prevStat) => {
                prevStat.ReportDescription = null
                return {...prevStat}
            })
        }
    }

    function submitReport() {
        if(ReportDescription.trim().length === 0) {
            setErrors((prevStat) => {
                prevStat.ReportDescription = 'Please enter a report description'
                return {...prevStat}
            })
            setErrorValidation(true)
            return 
        }

        const SendData = {
            "Post_ID": ReportData.Post_ID,
            "ReportType": ReportType,
            "Description": ReportDescription
        }
        if(ReportData.type === 'CommentReport') {
            SendData.Comment_ID = ReportData.data.ID
        }
        axios.post(`/reports`, SendData).then(res => {
            if (res.data.message === 'Success') {
                setShowSuccess(true);

                setTimeout(() => {
                    setReportData(null);
                }, 5000);
            }
        })

        console.log(SendData);

        // setReportData(null)
    }

    useEffect(() => {
        // console.log(ReportData)
        if(ReportData) {

            if(ReportMessages === null) {
                getReportMessages();
              }
            setShow(true);
        } 
        if(ReportData === null) {
            setReportType(null)
            setReportDescription('')
            setShow(false);
            setShowSuccess(false);
        }
    }, [ReportData])
    
    // console.log(ReportMessages, ReportData)
    return (
        <>
            <Modal show={show} className='themeModal' backdrop="static" centered onHide={handleClose}>
                {
                    !ShowSuccess &&
                        <Modal.Header className='border-0'>
                            <Modal.Title>
                                <img src={selectedAvatar ? selectedAvatar : defaultAvatars[1].url} placeholder='profile photo' style={{'width': '30px'}} />
                            </Modal.Title>
                        </Modal.Header>
                }
                <Modal.Body className='border-0 py-0'>
                    {
                        ShowSuccess ?
                        <>
                        <div className='ReportSuccess'>
                            <SvgIcon icon="checkCircle" className='LikedPost' size="50" />
                            <p className='text-center fw-900 mb-0'>Thank you for reporting, <br /> we will look into it soon.</p>
                        </div>
                        </>
                        :
                        <div className='FormReport'>
                            <div className='selectContainer'>
                                <SvgIcon icon='caretCircleDown-fill' fill='#767676' />
                                <select id="DropdownReportSelect" className='form-control' onChange={(e) => setReportType(e.target.value)}>
                                    {
                                        ReportMessages && ReportData && <>
                                        {
                                            ReportMessages[ReportData.type]?.map((item) => {
                                                return <option value={item}>{item}</option>
                                            })
                                        }
                                        
                                        </>
                                    }
                                </select>
                            </div>
                            <textarea rows="4" value={ReportDescription} onChange={(e) =>  changeDescription(e.target.value)} className='form-control' placeholder='Describe' />
                            {errors.ReportDescription && <div className="alert alert-danger">{errors.ReportDescription}</div>}
                        </div>
                    }
                </Modal.Body>
                {
                    !ShowSuccess &&
                    <Modal.Footer className='border-0'>
                        <div className='d-flex gap-1 w-100'>
                            <button className="primaryThemeButtonTransparent" onClick={handleClose}>
                                Close
                            </button>
                            <button className='primaryThemeButton' onClick={submitReport}>
                                Report
                            </button>
                        </div>
                    </Modal.Footer>
                }
            </Modal>
        </>
    )
}
const mapStateToProps = ({ auth, post }) => {

  const {
      userCategory,
      userDeviceDetails,
      selectedAvatar,
      defaultAvatars,
      isLogin,
      userLocationDetails
  } = auth

  const {
    ReportMessages
  } = post

  return {
      userCategory,
      userDeviceDetails,
      selectedAvatar,
      defaultAvatars,
      isLogin,
      userLocationDetails,
      ReportMessages
  }
}

const mapDispatchToProps = {
    getReportMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportModal)
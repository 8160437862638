import React from 'react'
import '../Pages/AllPostViewCard.css'
import SvgIcon from '../../svg/SvgIcon'

function DownloadCard({
    item
}) {
  return (
    <div
    className='CompletedCard FoodDetailCard DownloadCard'
    // onScroll={handleScroll}
    // onTouchStart={handleTouchStart}
    // onTouchMove={handleTouchMove}
    // onTouchEnd={(e) => handleTouchEnd(e, 'DoneCard')}
    >
        <div className='Top'>
            <div className='checkContainer'>
              <img src='/assets/img/icon/SealCheck.svg' alt='Check' className='checkMarkBg' />
              <SvgIcon icon='download-fill' size="35" fill='#ffffff' />
            </div>
            {/* {
              item.Key1 &&
              <h4>{item.Key1}</h4>
            } */}
            {
              item.Key2 &&
              <p>{item.Key2}</p>
            }

            {
              item.Key3 &&
              <a href={item.Key3} target='_blank'>
                <button className='primaryThemeButton'>{item.Key1 ? item.Key1 : 'Download Now'}</button>
              </a>
            }
        </div>
    </div>
  )
}

export default DownloadCard
import React, { useState, useEffect } from 'react';
import config from '../../config'
import { connect } from "react-redux"
import { setUserData, registerUser, updateUserName } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router';

function RegisterYourName({
    userName,
    setUserData,
    isLogin,
    registerUser,
    userNumber,
    isCordova,
    updateUserName
}) {
    const navigate = useNavigate();

    let device;

    const [isValidationActive, setValidationActive] = useState(false);
    const [Errors, setErrors] = useState({
        'Name': null
    });

    const [userNameToEnter, setUserNameToEnter] = useState('');

    const changeName = (e) => {
        const enterValue = e.target.value;
        setUserNameToEnter(enterValue);

        if(isValidationActive) {
            const tempError = JSON.parse(JSON.stringify(Errors));
            if(enterValue.toString().trim().length === 0) {
                tempError['Name'] = 'Please enter your name';
            } else  {
                tempError['Name'] = null;
            }
            setErrors(tempError);
        }
    }

    function onDeviceReady() {
        let string = device.uuid;
        return string
    }

    async function RegisterYourName(tempObj) {
        const getConfirmation  = await registerUser(tempObj);
        console.log(getConfirmation);
        if(getConfirmation.payload.data.token) {
            navigate('/loading');
        }
    }

    async function UpdateUserName(tempObj) {
        const getConfirmation2 = await updateUserName(tempObj);
        console.log(getConfirmation2);
        if(getConfirmation2.payload.response.data.message === 'Success') {
            navigate('/FoodJointsNearby');
        }
    }

    function registerUsers() {

        const tempError = JSON.parse(JSON.stringify(Errors));
        // console.log(userNameToEnter);
        if(userNameToEnter.toString().trim().length === 0) {
            tempError['Name'] = 'Please enter your name';
            setErrors(tempError);
            setValidationActive(true);
            return 
        }
        
        tempError['Name'] = null;
        setErrors(tempError);
        setValidationActive(false);
        const tempObj = {
            'UserMobileNumber' : userNumber, 'UserName' : userNameToEnter 
        }
        if(isCordova) {
            tempObj.Device_ID = localStorage.getItem('Device_ID');
        }
        if(!isLogin) {
            RegisterYourName(tempObj)
        } else {
            UpdateUserName(tempObj)
        }
        

        // navigate('/ChooseAvatar');
    }

    useEffect(() => {
        if(userName) {
            setUserNameToEnter(userName);
        }
    }, [userName])

    useEffect(() => {
      if(userNumber === null) {
        navigate('/login');
      }
    }, [userNumber])
    
    return (
        <div className='TopBgImage padding-2 height100vh'>
            <div className='RegisterYourNumberPage'>
                <div className='TopSection'>
                    <h3>foodaspas</h3>
                </div>
                <div className='MiddleSection'>
                    <img src='assets/img/NamePage.svg' alt='Verify Your Number' />
                    <h2 className='text-center screenTitle'>Discovering your nearest food joints through vlogs</h2>
                </div>

                <div className='BottomSection'>
                    <div className={`OtpValidation  ${Errors.Name && 'invalid'}`}>
                        <input type="text" autoFocus placeholder='Enter Your Name' value={userNameToEnter} onChange={changeName} className="form-control" />
                        {Errors.Name && <div class="invalid-feedback text-center">{Errors.Name}</div>}
                    </div>
                    {/* {
                        isLogin ?
                        <button className='primaryThemeButton' onClick={() => registerUser()}>
                            Continue
                        </button>
                        :
                    } */}
                        <button className='primaryThemeButton' onClick={() => registerUsers()}>
                            Continue
                        </button>

                                        
                    {/* <button className='primaryThemeButtonTransparent' onClick={() => navigate('/login')}>
                        Go Back
                    </button> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {

    } = appToast

    const {
        userName,
        isLogin,
        userNumber,
        isCordova
    } = auth

    return {
        userName,
        isLogin,
        userNumber,
        isCordova
    }
}

const mapDispatchToProps = {
    setUserData,
    registerUser,
    updateUserName
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterYourName)
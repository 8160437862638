import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { setToken, setUserData, setDeviceDetails, setSelectedCity, setUserLocationDetails, setPostMethod, setLocation } from './features/auth/authSlice'
import Authentication from './components/Pages/Authentication'
import Layout from './components/Pages/Layout'
import Load from './components/Pages/Load'
import AppInfo from './components/Pages/AppInfo'
import jwt_decode from 'jwt-decode'
import GetStarted from './components/Pages/GetStarted'
import Header from './components/layout/Header/Header'
import Footer from './components/layout/Footer/Footer'
import Summary from './components/Pages/Summary'
import WaitingScreen from './components/Pages/WaitingScreen'
import GetSetLocation from './components/Pages/GetSetLocation'
import GetSelectCategories from './components/Pages/GetSelectCategories'
import RegisterYourNumber from './components/Pages/RegisterYourNumber'
import ProfileSection from './components/Pages/ProfileSection'
import TopJointsNearYou from './components/Pages/TopJointsNearYou'
// import FoodDetailsPageContainer from './components/Pages/FoodDetailsPageContainer'
import RegisterYourName from './components/Pages/RegisterYourName'
import UploadProfileImage from './components/Pages/UploadProfileImage'
import Wrapper from './components/Pages/Wrapper'
import FoodDetailsPageWrapper from './components/Pages/FoodDetailsPageWrapper'
import ChooseAvatar from './components/Pages/ChooseAvatar'
import SelectMainCity from './components/Pages/SelectMainCity'
import AreYouHungry from './components/Pages/AreYouHungry'
import Filters from './components/Pages/Filters'
import useGetSetLocationToLocal from './hooks/useGetSetLocationToLocal'
// import { isDeviceMobile } from './config'
import FoodDetailsSharePage from './components/Pages/FoodDetailsSharePage'
import axios from '../src/AxiosConfig';
import config from './config'
import DeleteUser from './components/Pages/DeleteUser'

const App = ({
  authToken,
  setToken,
  setUserData,
  setDeviceDetails,
  setSelectedCity,
  setUserLocationDetails,
  setPostMethod,
  setLocation
}) => {
  const { getPlaceFromLocal, getPlaceLatLong, getSelectedFromLocal } = useGetSetLocationToLocal();
  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData) {
        console.log(tokenData, new Date(tokenData.exp * 1000), new Date())
        if(new Date(tokenData.exp * 1000) > new Date()) {
            if (tokenData.userInfo.hasOwnProperty('ID')) {
              setToken({ authToken: token })
              return true
            } else {
              // localStorage.clear()
              console.log('clear token 1');
              return false
            }
        } else {
          localStorage.clear();
          console.log('clear token 2');
          return false
        }
      } else {
        // localStorage.clear()
        // console.error('oneeeeeeeeeee');
        return false
      }
    }
  }

  useLayoutEffect(() => {
    const userTempLocation = getPlaceFromLocal();
    const userTempLocationDetails = getPlaceLatLong();

    const userSelectedLocation = getSelectedFromLocal();
    if(userTempLocation && userTempLocationDetails) {
      console.log(userTempLocation, userTempLocationDetails);
      setPostMethod('static');
      // setSelectedCity(userTempLocation);
      // setUserLocationDetails(JSON.parse(userTempLocationDetails));
      setLocation({ 
        "userLocation" : userTempLocation, "userLocationDetails" : JSON.parse(userTempLocationDetails)
      })
    }

    if(userSelectedLocation) {
      setSelectedCity(userSelectedLocation);
    }
  }, [])
  const userLoggedIn = validateToken()
  // console.log(userLoggedIn)
  
  // useEffect(() => {
  //   if(localStorage.getItem('userName')) {
  //     setUserData({'userName': JSON.parse(JSON.stringify(localStorage.getItem('userName')))})
  //   }

  //   if(localStorage.getItem('userLocation')) {
  //     setUserData({'userLocation': JSON.parse(JSON.stringify(localStorage.getItem('userLocation')))})
  //   }

  //   if(localStorage.getItem('userNumber')) {
  //     setUserData({'userNumber': JSON.parse(JSON.stringify(localStorage.getItem('userNumber')))})
  //   }

  //   if(localStorage.getItem('userCategory')) {
  //     setUserData({'userCategory': JSON.parse(localStorage.getItem('userCategory'))})
  //   }
  // }, [])

  function ResizeWindow() {
    const screenSize = {};
    screenSize.innerWidth = window.innerWidth;
    screenSize.innerHeight = window.innerHeight;
    // console.log(screenSize);
    setDeviceDetails(screenSize);
    let vh = window.innerHeight * 0.01;
    // console.log(vh, window.innerHeight, window.innerWidth);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.setAttribute('deviceHight', window.innerHeight);
  }

  function ResizeCommentWindow() {
    const screenSize = {};
    screenSize.innerWidth = window.innerWidth;
    screenSize.innerHeight = window.innerHeight;
    // console.log(screenSize);
    setDeviceDetails(screenSize);
    let vh = window.innerHeight * 0.01;
    // console.log(vh, window.innerHeight, window.innerWidth);
      document.documentElement.style.setProperty('--CMvh', `${vh}px`);
      // document.documentElement.setAttribute('deviceHight', window.innerHeight);
  }
  
  useEffect(() => {
    window.addEventListener("resize", () => {
      ResizeCommentWindow()
    });
    ResizeCommentWindow();
    ResizeWindow();
    return () => {
        window.removeEventListener("resize", () => {
          ResizeCommentWindow()
        })
    }

}, []);

  const [version, setVersion] = useState('');

  useEffect(() => {
  // Wait for Cordova to be ready
  document.addEventListener('deviceready', onDeviceReady, false);

    async function onDeviceReady() {
      const version =  localStorage.getItem('AppVersion');
      if(version) {
          axios.get(`/users/checkAppUpdate/${version}`)
            .then(res => {
                if (res.data.data !== 'false') {
                  window.location.href = res.data.data;
                }
            })
            .catch(err => {
                console.error(err);
            });
      }
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener('deviceready', onDeviceReady, false);
    };
  }, []);
  
  return (
    <div className="App AspasApp">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn && 
          <Fragment>
            {/* {
              !isDeviceMobile ?
              : */}
              <>
              <Route path="*" element={<Waiting />} />
              <Route path="login" element={<RegisterYourNumberView />} />
              <Route path="loading" element={<Waiting />} />
              <Route path='RegisterYourNumber' element={<RegisterYourNumberView />} />
              <Route path='RegisterYourName' element={<RegisterYourNameView />} />
              <Route path='FoodJointsNearby/:FoodPostID' element={<FoodDetailsSharePageView />} />
              </>
            {/* } */}
            {/* <Route path="getStarted" element={<GetStartedView />} /> */}
            {/* <Route path='getLocation' element={<GetSetLocationView />} /> */}
            {/* <Route path='getSelectedCategories' element={<GetSelectCategoriesView />} />
            <Route path='TopJointsNearYou' element={<TopJointsNearYouView />} />
            <Route path='FoodJointsNearby' element={<FoodDetailsPageView />} />
            <Route path='ChooseAvatar' element={<ChooseAvatarView />} />
            <Route path='ChooseCity' element={<SelectMainCityView />} />
            <Route path='AreYouHungry' element={<HungryPageView />} />
          <Route path='Filters' element={<FiltersView />} /> */}
            {/* <Route path="getStartedLogin" element={<GetStartedLoginView />} /> */}
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            <Route path="*" element={<Waiting />} />
            <Route path='AreYouHungry' element={<HungryPageView />} />
            <Route path='FoodJointsNearby' element={<FoodDetailsPageView />} />
            <Route path="loading" element={<Waiting />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path='getLocation' element={<GetSetLocationView />} />
            <Route path='getSelectedCategories' element={<GetSelectCategoriesView />} />
            {/* <Route path='RegisterYourNumber' element={<RegisterYourNumberView />} /> */}
            <Route path='RegisterYourName' element={<RegisterYourNameView />} />
            <Route path='Profile' element={<ProfilePageView />} />
            <Route path='Filters' element={<FiltersView />} />
            <Route path='UploadProfileImage' element={<UploadProfileImageView />} />
            <Route path='ProfilePage' element={<ProfilePageView />} />
            <Route path='TopJointsNearYou' element={<TopJointsNearYouView />} />
            <Route path='FoodJointsNearby/:FoodPostID' element={<FoodDetailsPageView />} />
            <Route path='account-deletion' element={<DeleteView />} />
            {/* <Route path="/" element={<AboutApp />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="summary" element={<SummaryView />} />
            <Route path="*" element={<Auth />} /> */}
          </Fragment>
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <Wrapper>
      <div className="h-100 d-flex justify-content-center align-items-center TopBgImage">
        <Authentication></Authentication>
      </div>
    </Wrapper>
  )
}

function SelectWebsiteList() {
  return (
    <Wrapper>
      <div className="App">
        <Load></Load>
      </div>
    </Wrapper>
  )
}

function Waiting() {
  return (
    <>
    <Wrapper>
      <WaitingScreen></WaitingScreen>
    </Wrapper>
    </>
  )
}

function AboutApp() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Wrapper>
        <Header></Header>
          <AppInfo></AppInfo>
        <Footer></Footer>
      </Wrapper>
      {/* </Layout> */}
    </div>
  )
}

function GetStartedView() {
  return (
    <Wrapper>
      <div className="App">
      {/* <Layout> */}
        <GetStarted></GetStarted>
      {/* </Layout> */}
      </div>
    </Wrapper>
  )
}
function GetSetLocationView(){
  return (
    <Wrapper>
      <div className='App'>
        <GetSetLocation />
      </div>
    </Wrapper>
  )
}
function GetSelectCategoriesView(){
  return (
    <Wrapper>
      <div className='App'>
        <GetSelectCategories />
      </div>
    </Wrapper>
  )
}

function RegisterYourNumberView(){
  return (
    <Wrapper>
      <div className='App'>
        <RegisterYourNumber />
      </div>
    </Wrapper>
  )
}

function RegisterYourNameView() {
  return (
    <Wrapper>
      <div className='App'>
        <RegisterYourName />
      </div>
    </Wrapper>
  )
}

function ChooseAvatarView() {
  return (
    <Wrapper>
      <div className='App'>
        <ChooseAvatar />
      </div>
    </Wrapper>
  )
}

function SelectMainCityView() {
  return (
    <Wrapper>
      <div className='App'>
        <SelectMainCity />
      </div>
    </Wrapper>
  )
}

function UploadProfileImageView() {
  return (
    <Wrapper>
      <div className='App'>
        <UploadProfileImage />
      </div>
    </Wrapper>
  )
}

function TopJointsNearYouView() {
  return (
    <Wrapper>
      <div className='App'>
        <TopJointsNearYou />
      </div>
    </Wrapper>
  )
}

function FoodDetailsPageView() {
  return (
    <Wrapper>
      <div className='App'>
        {/* <FoodDetailsPageContainer /> */}
        <FoodDetailsPageWrapper />
      </div>
    </Wrapper>
  )
}

function FoodDetailsSharePageView() {
  return (
    <Wrapper>
      <div className='App'>
        <FoodDetailsSharePage />
        {/* <FoodDetailsPageContainer /> */}
        {/* <FoodDetailsPageWrapper /> */}
      </div>
    </Wrapper>
  )
}

function ProfilePageView() {
  return  (
    <Wrapper>
      <div className='App'>
        <ProfileSection />
      </div>
    </Wrapper>
  )
}

function HungryPageView() {
  return  (
    <Wrapper>
      <div className='App'>
        <AreYouHungry />
      </div>
    </Wrapper>
  )
}

function DeleteView() {
  return  (
    <Wrapper>
      <div className='App'>
        <DeleteUser />
      </div>
    </Wrapper>
  )
}

function FiltersView() {
  return  (
    <Wrapper>
      <div className='App'>
        <Filters />
      </div>
    </Wrapper>
  )
}
function SummaryView() {
  return (
    <Wrapper>
      <div className="App">
      <Layout>
        <Summary></Summary>
      </Layout>
      </div>
    </Wrapper>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken
  } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken,
  setDeviceDetails,
  setUserData,
  setSelectedCity,
  setUserLocationDetails,
  setPostMethod,
  setLocation
}

export default connect(mapStateToProps, mapDispatchToProps)(App)